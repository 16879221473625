import firebase from "firebase/app";
import axios from "axios";
import store from "@/store";

import router from "@/router";

const REQUESTS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

async function get_auth_id_token() {
  const id_token = await firebase.auth().currentUser.getIdToken();
  return id_token;
}

async function get_auth_user_name() {
  const user_name = await firebase.auth().currentUser.displayName;
  return user_name;
}

async function make_request(
  method,
  endpoint,
  params,
  options = { add_user_name: false }
) {
  const id_token = await get_auth_id_token();
  const server_url = store.state.server_url;
  if (options.add_user_name) {
    const user_name = await get_auth_user_name();
    params["user_name"] = user_name;
  }
  const response = await axios[method](`${server_url}${endpoint}`, {
    id_token,
    ...params,
  });
  return response;
}

async function make_api_request(
  method,
  endpoint,
  params,
  allow_unauth = false
) {
  const api_url = store.state.api_server_url;
  if (!allow_unauth && !axios.defaults.headers.common["Authorization"])
    router.replace("/logout");
  const response = await axios[method](`${api_url}${endpoint}`, params);
  return response;
}

/**************************  FIREBASE FUNCTIONS  *****************************/

/* SHOPS */

export async function create_shop(params) {
  await make_request(REQUESTS.POST, "/shops", params);
}

/* DELIVERIES */

export async function add_order_to_collaboration(params) {
  await make_request(REQUESTS.POST, "/deliveries", params);
}

export async function claim_orders_for_delivery(params) {
  await make_request(REQUESTS.POST, "/deliveries/claim", params, {
    add_user_name: true,
  });
}

export async function close_collaboration_order(
  shop_id,
  order_id,
  collaboration_order_id
) {
  await make_request(REQUESTS.POST, "/deliveries/close", {
    shop_id,
    order_id,
    collaboration_order_id,
  });
}

export async function complete_delivery_route(params) {
  await make_request(REQUESTS.POST, "/deliveries/complete", params, {
    add_user_name: true,
  });
}

/* ORDERS */

export async function update_orders(shop_id, order_ids, updates) {
  await make_request(REQUESTS.PUT, "/orders", { shop_id, order_ids, updates });
}

/* PRODUCTS */

export async function update_products(shop_id, products_ids, updates) {
  await make_request(REQUESTS.PUT, "/products", {
    shop_id,
    products_ids,
    updates,
  });
}

/* REPORTS */

export async function download_order_reports(params) {
  const response = await make_request(REQUESTS.POST, "/reports", params);
  return response;
}

/**************************  FAST API BACKEND *****************************/

/* STRIPE CONNECT */

export async function create_stripe_account(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    "/stripe-connect/create-account",
    params
  );
  return response;
}

export async function create_stripe_account_link(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    "/stripe-connect/create-account-link",
    params
  );
  return response;
}

export async function create_stripe_login_link(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    "/stripe-connect/create-login-link",
    params
  );
  return response;
}

/* MANAGEMENT */

export async function reset_shop_admin_password(shop_id) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/reset_shop_admin_password/${shop_id}`
  );
  return response;
}

/* USERS */

export async function update_current_user(params) {
  const response = await make_api_request(REQUESTS.PUT, `/users`, params);
  return response;
}

export async function create_brand_manager(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/users/brand_manager`,
    params
  );
  return response;
}

export async function deactivate_brand_manager(manager_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/users/brand_manager/${manager_id}`
  );
  return response;
}

export async function reset_password(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    "/reset_password",
    params,
    true
  );
  return response;
}

export async function export_shop_admins() {
  const response = await make_api_request(
    REQUESTS.GET,
    "/users/shop_admin/export/csv"
  );
  return response;
}

/* HOME PAGE ELEMENTS */

export async function upsert_home_page_element(element_in) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/home_page_elements`,
    element_in
  );
  return response;
}

export async function delete_home_page_element(element_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/home_page_elements/${element_id}`
  );
  return response;
}

export async function move_home_page_element_in_hierarchy(
  element_id,
  direction
) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/home_page_elements/${element_id}/${direction}`
  );
  return response;
}

/* BLOG_POSTS */

export async function get_blog_post_by_id(post_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/blog_posts/${post_id}`
  );
  return response;
}

export async function get_blog_posts(params) {
  const response = await make_api_request(REQUESTS.GET, `/blog_posts`, {
    params,
  });
  return response;
}

export async function upsert_blog_post(blog_post_in) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/blog_posts`,
    blog_post_in
  );
  return response;
}

export async function delete_blog_post(blog_post_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/blog_posts/${blog_post_id}`
  );
  return response;
}

export async function change_custom_page_order(custom_page_id, direction) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/blog_posts/${custom_page_id}/${direction}`
  );
  return response;
}

/* PROMOTIONS */

export async function get_promotion_by_id(promotion_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/promotions/${promotion_id}`
  );
  return response;
}

export async function delete_promotion(promotion_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/promotions/${promotion_id}`
  );
  return response;
}

export async function upsert_promotion(promotion_in) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/promotions`,
    promotion_in
  );
  return response;
}

/* BRAND */

export async function get_brands() {
  const response = await make_api_request(REQUESTS.GET, `/brands`);
  return response;
}

export async function get_brand_promotions(brand_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/brands/${brand_id}/promotions`
  );
  return response;
}

export async function get_brand_by_id(brand_id) {
  const response = await make_api_request(REQUESTS.GET, `/brands/${brand_id}`);
  return response;
}

export async function add_brand(new_brand) {
  const response = await make_api_request(REQUESTS.POST, `/brands`, new_brand);
  return response;
}

export async function update_brand(brand_id, updates) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/brands/${brand_id}`,
    updates
  );
  return response;
}

export async function update_brand_active_promotion(brand_id, updates) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/brands/${brand_id}/active_promotion`,
    updates
  );
  return response;
}

export async function associate_shop_with_brand(brand_id, shop_id) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/brands/${brand_id}/associate/${shop_id}`
  );
  return response;
}

export async function dissociate_shop_from_brand(brand_id, shop_id) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/brands/${brand_id}/dissociate/${shop_id}`
  );
  return response;
}

export async function get_brand_managers(brand_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/brands/${brand_id}/managers`
  );
  return response;
}

/* BRAND_ZONE */

export async function add_brand_zone(new_brand_zone) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/brand_zones`,
    new_brand_zone
  );
  return response;
}

export async function update_brand_zone(brand_zone_id, params) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/brand_zones/${brand_zone_id}`,
    params
  );
  return response;
}

export async function delete_brand_zone(brand_zone_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/brand_zones/${brand_zone_id}`
  );
  return response;
}

export async function associate_shop_with_brand_zone(brand_zone_id, shop_id) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/brand_zones/${brand_zone_id}/associate/${shop_id}`
  );
  return response;
}

export async function dissociate_shop_from_brand_zone(brand_zone_id, shop_id) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/brand_zones/${brand_zone_id}/dissociate/${shop_id}`
  );
  return response;
}

/* SHOP */

export async function get_shops(params) {
  const response = await make_api_request(REQUESTS.GET, `/shops`, { params });
  return response;
}

export async function get_shop(shop_id) {
  const response = await make_api_request(REQUESTS.GET, `/shops/${shop_id}`);
  return response;
}

/* SERVICE */

export async function upsert_service(params) {
  const response = await make_api_request(REQUESTS.POST, "/services", params);
  return response;
}

/* STAFF */

export async function get_staff(params) {
  const response = await make_api_request(REQUESTS.GET, "/staff", params);
  return response;
}

export async function create_staff(name, role, pin) {
  const response = await make_api_request(REQUESTS.POST, "/staff", {
    name,
    role,
    pin,
  });
  return response;
}

export async function register_staff_user(email, password, confirm_password) {
  const response = await make_api_request(REQUESTS.POST, "/staff/register", {
    email,
    password,
    confirm_password,
  });
  return response;
}

export async function update_staff(staff_id, params) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/staff/${staff_id}`,
    params
  );
  return response;
}

export async function update_staff_pin(staff_id, old_pin, new_pin) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/staff/${staff_id}/pin`,
    { old_pin, new_pin }
  );
  return response;
}

export async function validate_staff_login(staff_id, pin) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/staff/${staff_id}/check_pin`,
    { pin }
  );
  return response;
}

/* BUSINESS HOURS */

export async function get_business_hours(shop_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/business_hours/${shop_id}`
  );
  return response;
}

export async function upsert_business_hours(business_hours_in) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/business_hours`,
    business_hours_in
  );
  return response;
}

export async function delete_business_hour(business_hours_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/business_hours/${business_hours_id}`
  );
  return response;
}

/* BOOKING SETTINGS */

export async function get_booking_settings(shop_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/booking/settings/${shop_id}`
  );
  return response;
}

export async function upsert_booking_settings(settings_in) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/booking/settings`,
    settings_in
  );
  return response;
}

/* TABLE ZONES */

export async function get_zones(shop_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/booking/table_zones/${shop_id}`
  );
  return response;
}

export async function add_zone(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/booking/table_zones`,
    params
  );
  return response;
}

export async function update_zone(params) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/booking/table_zones`,
    params
  );
  return response;
}

/* TABLES */

export async function get_tables(shop_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/booking/tables/${shop_id}`
  );
  return response;
}

export async function upsert_tables(settings_in) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/booking/tables`,
    settings_in
  );
  return response;
}

export async function delete_table(table_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/booking/tables/${table_id}`
  );
  return response;
}

export async function get_guests_options(shop_id, params) {
  params.client_timezone_offset = new Date().getTimezoneOffset();
  const response = await make_api_request(
    REQUESTS.GET,
    `/booking/tables/${shop_id}/admin/guests_options`,
    { params }
  );
  return response;
}

export async function get_available_table_start_times(shop_id, params) {
  params.client_timezone_offset = new Date().getTimezoneOffset();
  const response = await make_api_request(
    REQUESTS.GET,
    `/booking/tables/${shop_id}/admin/available_times`,
    { params }
  );
  return response;
}

/* BOOKINGS */

export async function create_booking_block_out(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    "/booking/block-out",
    params
  );
  return response;
}

export async function get_booking_block_outs(shop_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/booking/block-out/${shop_id}`
  );
  return response;
}

export async function delete_block_out(block_out_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/booking/block-out/${block_out_id}`
  );
  return response;
}

export async function get_booking(booking_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/booking/${booking_id}`
  );
  return response;
}

export async function patch_booking(booking_id, params) {
  const response = await make_api_request(
    REQUESTS.PATCH,
    `/booking/${booking_id}`,
    params
  );
  return response;
}

export async function get_bookings(params) {
  const response = await make_api_request(REQUESTS.GET, `/booking`, { params });
  return response;
}

export async function get_available_dates(shop_id, params) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/booking/available_dates/admin/${shop_id}`,
    {
      params: {
        ...params,
        client_timezone_offset: new Date().getTimezoneOffset(),
      },
    }
  );
  return response;
}

export async function create_booking(params) {
  const response = await make_api_request(REQUESTS.POST, `/booking`, params);
  return response;
}

export async function create_group_table_booking(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/booking/group-table-booking`,
    params
  );
  return response;
}

export async function update_booking(params) {
  const response = await make_api_request(REQUESTS.PUT, "/booking", params);
  return response;
}

export async function update_bookings(params) {
  const response = await make_api_request(
    REQUESTS.PUT,
    "/booking/bulk",
    params
  );
  return response;
}

/* REVIEWS */

export async function get_shop_reviews(shop_id, params) {
  const response = await make_api_request(REQUESTS.GET, `/reviews/${shop_id}`, {
    params,
  });
  return response;
}

export async function update_shop_review(review_id, params) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/reviews/${review_id}`,
    params
  );
  return response;
}

export async function delete_shop_review(review_id) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/reviews/${review_id}`
  );
  return response;
}

/* IMAGES */

export async function get_images(params) {
  const response = await make_api_request(REQUESTS.GET, `/images`, { params });
  return response;
}

export async function upload_image(form_data) {
  const response = await make_api_request(REQUESTS.POST, "/images", form_data, {
    headers: form_data.headers,
  });
  return response;
}

export async function delete_images(params) {
  const response = await make_api_request(REQUESTS.DELETE, "/images", {
    data: params,
  });
  return response;
}

/* ADD A BUSINESS REQUESTS */

export async function get_add_a_business_by_brand(brand_id, params) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/action_requests/add_a_business/brand/${brand_id}`,
    { params }
  );
  return response;
}

export async function get_add_a_business_request(add_a_business_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/action_requests/add_a_business/${add_a_business_id}`
  );
  return response;
}

export async function update_add_a_business_request(request_id, params) {
  const response = await make_api_request(
    REQUESTS.PUT,
    `/action_requests/add_a_business/${request_id}`,
    params
  );
  return response;
}

export async function create_request_resolution_task(
  shop_id,
  add_a_business_id
) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/action_requests/add_a_business/task/${shop_id}/${add_a_business_id}`
  );
  return response;
}

export async function get_add_a_business_pending_requests_count(brand_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/action_requests/add_a_business/brand/${brand_id}/pending_count`
  );
  return response;
}

export async function reject_add_a_business(add_a_business_id, params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/action_requests/add_a_business/reject/${add_a_business_id}`,
    params
  );
  return response;
}

export async function get_collection_images(shop_id, collection_name) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/images/collection/${collection_name}/${shop_id}`
  );
  return response;
}

export async function add_image_to_collection(
  image_id,
  collection_name,
  shop_id
) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/images/${image_id}/collection/${collection_name}/${shop_id}`
  );
  return response;
}

export async function move_collection_image_before_image(
  collection_name,
  shop_id,
  image_id,
  before_image_id
) {
  const response = await make_api_request(
    REQUESTS.PATCH,
    `/images/collection/${collection_name}/${shop_id}/move/${image_id}/before/${before_image_id}`
  );
  return response;
}

export async function move_collection_image_after_image(
  collection_name,
  shop_id,
  image_id,
  after_image_id
) {
  const response = await make_api_request(
    REQUESTS.PATCH,
    `/images/collection/${collection_name}/${shop_id}/move/${image_id}/after/${after_image_id}`
  );
  return response;
}

export async function delete_collection_image(
  collection_name,
  shop_id,
  image_id
) {
  const response = await make_api_request(
    REQUESTS.DELETE,
    `/images/${image_id}/collection/${collection_name}/${shop_id}`
  );
  return response;
}

export async function get_payment_intent(intent_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/stripe-connect/payment-intent/${intent_id}`
  );
  return response;
}

export async function capture_payment_intent(intent_id) {
  const response = await make_api_request(
    REQUESTS.GET,
    `/stripe-connect/payment-intent/capture/${intent_id}`
  );
  return response;
}

// added by vikas
// function to check terminal status online / offline
export async function check_terminal_status() {
  const response = await make_api_request(
    REQUESTS.POST,
    `/stripe-connect/check-terminal-status`
  );
  return response;
}

// function to initiage payment with stripe terminal
export async function initiate_payment(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/stripe-connect/initiate-payment`,
    params
  );
  return response;
}

// function to create payment intent payment with stripe terminal
export async function create_terminal_payment_intent(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/stripe-connect/create-terminal-payment-intent`,
    params
  );
  return response;
}

// function to Process payment intent payment with stripe terminal
export async function process_terminal_payment_intent(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/stripe-connect/process-terminal-payment-intent`,
    params
  );
  return response;
}

// function to Capture payment intent payment with stripe terminal
export async function capture_terminal_payment(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/stripe-connect/capture-terminal-payment`,
    params
  );
  return response;
}

// function to Capture payment intent payment with stripe terminal
export async function check_terminal_payment_intent_status(params) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/stripe-connect/check-terminal-payment-intent-status`,
    params
  );
  return response;
}

// function to initiage payment and create order with stripe terminal
export async function create_order_payment_intent(shop_id, params) {
  console.log("create_order_payment_intent", shop_id, params);
  const response = await make_api_request(
    REQUESTS.POST,
    `/orders/${shop_id}/create_order_for_terminal_payment`,
    params
  );
  return response;
}

// added by vikas
export async function collect_no_show_charge(booking_id) {
  const response = await make_api_request(
    REQUESTS.POST,
    `/booking/collect-no-show-charge/${booking_id}`
  );
  return response;
}
