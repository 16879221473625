<template>
  <div class="booking-today-wrapper pa-7">
    <BookingDatePicker />
    <BookingList
      :total-bookings="
        bookings_chronologically.filter(
          (b) => !b.parent_booking_id && !b.booking_block_out_id
        ).length
      "
      :total-guests="total_guests"
    />
    <BookingActionBar
      :timeline-scale="timeline_scale"
      @change-scale="(scale) => (timeline_scale = scale)"
      @adding-walk-in="(walk_in) => (adding_walk_in = walk_in)"
    />
    <BookingTimeline
      v-for="zone in zones"
      :key="`booking-timeline_${zone.id}`"
      :business-hours="business_hours_for_selected_booking_date"
      :tables="tables_by_zone[zone.id]"
      :zone-name="zone.name"
      :scroll-position="timeline_scroll_position"
      :scale="timeline_scale"
      :wrapper-id="`booking-timeline_${zone.id}`"
      @refresh-data="refreshData"
      @scroll-timeline="(position) => (timeline_scroll_position = position)"
    />
    <!-- Changes by Vikas Table Booking -->
    <NewBookingDialog
      v-if="show_new_booking"
      :walk-in="adding_walk_in"
      @close="adding_walk_in = false"
      @refresh-data="refreshData"
    />

    <ViewBookingDialog
      v-if="selected_booking"
      @close="select_booking(null)"
      @refresh-data="refreshData"
    />
    <!-- Changes by Vikas Table Booking -->
    <BookingUpdateSnackbar />
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapGetters } from "vuex";

import { get_ms_until_multiple_of_minutes } from "@/utils/date_utils";
import BookingDatePicker from "./components/BookingDatePicker.vue";
import BookingList from "./components/BookingList.vue";
import BookingTimeline from "./components/BookingTimeline.vue";
import BookingActionBar from "./components/BookingActionBar.vue";
import NewBookingDialog from "./components/NewBookingDialog.vue";
import BookingUpdateSnackbar from "./components/BookingUpdateSnackbar";
import ViewBookingDialog from "@/views/dashboard/components/ViewBookingDialog";

export default {
  name: "BookingToday",
  data() {
    return {
      moment,
      timeline_scroll_position: 0,
      timeline_scale: 4,
      adding_walk_in: false,
      walk_in_availability_timeout: null,
      walk_in_availability_interval: null,
    };
  },
  components: {
    BookingDatePicker,
    BookingList,
    BookingTimeline,
    BookingActionBar,
    NewBookingDialog,
    ViewBookingDialog,
    BookingUpdateSnackbar,
  },
  computed: {
    ...mapState("TableBookingStore", [
      "bookings",
      "business_hours",
      "zones",
      "selected_booking_date",
      "selected_booking",
      "show_new_booking",
    ]),
    ...mapGetters("TableBookingStore", [
      "tables_by_zone",
      "bookings_chronologically",
    ]),
    ...mapState("AdminStore", ["shop_id"]),
    business_hours_for_selected_booking_date() {
      return this.business_hours
        ? this.business_hours[moment(this.selected_booking_date).isoWeekday()]
        : [];
    },
    total_guests() {
      const extra_guests = this.bookings_chronologically
        .filter((b) => !b.booking_block_out_id)
        .map((b) => {
          return b.extra_guest_count || 0;
        })
        .reduce((a, b) => a + b, 0);
      const total_guests = this.bookings_chronologically
        .filter((b) => !b.booking_block_out_id)
        .map((b) => {
          return b.guest_count;
        })
        .reduce((a, b) => a + b, 0);

      if (extra_guests) return `${total_guests} + ${extra_guests}`;
      else return total_guests.toString();
    },
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "get_bookings",
      "get_tables",
      "get_zones",
      "apply_filters",
      "get_business_hours",
      "set_selected_date",
      "select_booking",
      "get_available_dates",
      "get_available_walk_in_dates",
    ]),
    get_start_range_from_date(date_str) {
      return {
        start_gte: moment(date_str + "T00:00:00")
          .utc()
          .format(),
        start_lt: moment(date_str + "T23:59:59")
          .utc()
          .format(),
      };
    },
    // Changes by Vikas Table Booking
    refreshData() {
      this.get_bookings_for_day(this.selected_booking_date);
      this.get_available_walk_in_dates(this.shop_id);
    },
    // Changes by Vikas Table Booking
    async get_bookings_for_day(date_str) {
      const { start_gte, start_lt } = this.get_start_range_from_date(date_str);
      this.apply_filters({
        start_gte,
        start_lt,
        shop_id: this.shop_id,
        booking_type: "table",
        include_block_outs: true,
        limit: 9999,
        booking_status: null,
      });
      await this.get_bookings();
    },
  },
  watch: {
    selected_booking_date(date_str) {
      this.get_bookings_for_day(date_str);
    },
  },
  mounted() {
    this.get_available_dates(this.shop_id);
    this.get_available_walk_in_dates(this.shop_id);
    this.get_tables(this.shop_id);
    this.get_zones(this.shop_id);
    this.get_bookings_for_day(this.selected_booking_date);
    this.get_business_hours(this.shop_id);
    const ms_until_full_quarter = get_ms_until_multiple_of_minutes(1);
    this.walk_in_availability_timeout = setTimeout(() => {
      this.get_available_walk_in_dates(this.shop_id);
      this.walk_in_availability_interval = setInterval(
        () => this.get_available_walk_in_dates(this.shop_id),
        1000 * 60 * 5
      );
    }, ms_until_full_quarter);
  },
  destroyed() {
    if (this.walk_in_availability_timeout)
      clearTimeout(this.walk_in_availability_timeout);
    if (this.walk_in_availability_interval)
      clearInterval(this.walk_in_availability_interval);
  },
};
</script>

<style lang="scss" scoped>
.booking-today-wrapper {
  display: grid;
  grid-template-columns: 223px auto;
  grid-template-rows: auto auto;
  grid-column-gap: 28px;
}
</style>
