<template>
  <v-container v-if="shop" id="google-maps" fluid tag="section">
    <v-dialog
      v-if="collaboration_help_dialog"
      v-model="collaboration_help_dialog"
      width="500"
    >
      <v-card class="br-16">
        <v-card-title
          class="primary text-left white--text pb-4 font-weight-bold"
        >
          <b>Help</b>
        </v-card-title>

        <v-card-text>
          Check the box to see collaborative orders as pins on the map. You can
          claim these orders and deliver on behalf of others. If you want to
          offer your orders click on an order and mark it as
          <b>Ready + Collaborate</b>.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            text
            @click="collaboration_help_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="!business_cords" class="ma-0">
      <v-col class="pt-0">
        <v-alert
          type="info"
          color="purple"
          outlined
          text
          transition="scale-transition"
        >
          <div>
            <p class="font-weight-regular mb-1">
              Before using delivery planner please update your Business Location
              in
              <b>Settings</b>
            </p>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-else class="ma-0">
      <v-col cols="12" md="8">
        <v-card elevation="0">
          <v-card-title class="d-block pt-4">
            <div class="header-grid">
              <div>
                <p class="d-block pb-0 mb-0">
                  {{
                    `${number_of_orders_to_claim} Order${
                      number_of_orders_to_claim > 1 ? "s" : ""
                    } ready to deliver`
                  }}
                </p>
                <div class="mb-4">
                  <v-icon class="mr-4" @click="collaboration_help_dialog = true"
                    >mdi-help-circle</v-icon
                  >
                  <v-checkbox
                    color="primary"
                    class="d-inline-block"
                    v-model="show_collab_deliveries"
                    label="Show Collaboration Orders"
                    hide-details
                  ></v-checkbox>
                </div>
              </div>
              <div>
                <v-text-field
                  label="Service Date"
                  type="date"
                  v-model="selected_date"
                  hide-details
                  class="mt-6"
                  outlined
                  dense
                />
              </div>
            </div>
          </v-card-title>
          <GmapMap
            id="map"
            ref="gmap"
            style="width: 100%; height: 700px; z-index: 0"
            :center="{ lat: 51.4406768, lng: -2.6131866 }"
            :zoom="13"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false,
              clickableIcons: false,
            }"
          >
            <GmapMarker
              :position="{
                lat: this.shop.geolocation.lat,
                lng: this.shop.geolocation.lng,
              }"
              :icon="require('@/assets/shop-pin.png')"
              :size="{ width: 36, height: 56 }"
              :clickable="true"
              :draggable="false"
              :z-index="999"
            ></GmapMarker>
            <GmapMarker
              v-for="(shop, coord) in collab_shop_markers"
              :key="coord"
              :icon="require('@/assets/shop-pin-collab.png')"
              :position="{
                lat: Number(coord.split(';')[0]),
                lng: Number(coord.split(';')[1]),
              }"
              :draggable="false"
              @mouseover="active_coord = coord"
              @mouseout="active_coord = null"
            >
              <GmapInfoWindow
                :opened="active_coord === coord"
                @closeclick="active_coord = null"
              >
                <p class="text-bold">
                  {{ shop }}
                </p>
              </GmapInfoWindow>
            </GmapMarker>
            <GmapMarker
              v-for="(orders, coord) in markers"
              :key="coord"
              :icon="{
                url: set_pin_icon(orders),
                labelOrigin: { x: 28, y: 5 },
              }"
              :label="get_pin_label(orders)"
              :position="{
                lat: Number(coord.split(';')[0]),
                lng: Number(coord.split(';')[1]),
              }"
              :clickable="true"
              :draggable="false"
              @click="marker_click_handler(coord, orders)"
              @mouseover="active_coord = coord"
              @mouseout="orders.length == 1 ? (active_coord = null) : null"
            >
              <GmapInfoWindow
                :opened="active_coord === coord"
                @closeclick="active_coord = null"
              >
                <div
                  class="marker-info-window"
                  v-for="order in orders"
                  :key="order.id"
                  @click="handle_order_selection(order)"
                >
                  <div>
                    <p class="text-bold">
                      <v-icon
                        class="mr-2"
                        color="primary"
                        v-if="selected_orders.indexOf(order) > -1"
                        >mdi-check-circle</v-icon
                      >
                      <v-icon class="mr-2" v-else>mdi-radiobox-blank</v-icon>
                      <span v-if="order.shop" class="font-weight-bold">
                        {{ order.shop.name }} </span
                      >Order #{{ order.order_no }} ({{ order.status }})
                    </p>
                  </div>
                </div>
              </GmapInfoWindow>
            </GmapMarker>
          </GmapMap>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card :title="set_card_title()" elevation="0" class="px-5 py-4">
          <p class="text-h4 font-weight-bold mb-0">
            How to plan your deliveries:
          </p>
          <div v-if="selected_orders.length === 0" class="px-5 py-3">
            <ul class="px-2" style="list-style: decimal">
              <li class="pt-3">
                Select all orders that you want to deliver, choose your
                transport mode, and create your route.
              </li>
              <li class="pt-3">
                You will find your newly created delivery route in the ‘Planned
                Deliveries’ tab.
              </li>
            </ul>
          </div>
          <div
            v-else-if="Object.keys(current_route).length > 0"
            class="px-5 py-3"
          >
            <div v-for="(segment, coord, idx) in current_route" :key="coord">
              <b>#{{ idx + 1 }}</b>
              <p v-if="idx + 1 === Object.keys(current_route).length">
                <b>Back to Shop</b>
              </p>
              <p v-else>
                To: <span>{{ segment[0].end }}</span>
              </p>
              <p>Distance: {{ segment[0].distance }}</p>
            </div>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  class="mr-2"
                  outlined
                  @click="clear_map({ map: true, selected_orders: false })"
                  >Edit</v-btn
                >
                <v-btn color="primary" class="ma-0" @click="create_route()"
                  >Plan</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div v-else class="px-5 py-3">
            <v-select
              :items="transport_mode"
              label="Transport mode"
              v-model="selected_transport_mode"
            >
              <template slot="selection" slot-scope="data">
                <v-flex xs2>
                  <v-icon size="25px">{{ data.item.icon }}</v-icon>
                </v-flex>
                <v-flex class="ml-1">
                  {{ data.item.name }}
                </v-flex>
              </template>
              <template slot="item" slot-scope="data">
                <v-flex xs2>
                  <v-icon size="25px">{{ data.item.icon }}</v-icon>
                </v-flex>
                <v-flex class="ml-1">
                  {{ data.item.name }}
                </v-flex>
              </template>
            </v-select>
            <p v-for="(order, index) in selected_orders" :key="index">
              <span v-if="order.shop" class="font-weight-bold">
                {{ order.shop.name }} </span
              >Order #{{ order.order_no }}
            </p>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn class="ma-0" color="primary" @click="get_route"
                  >Create route</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { uniqBy } from "lodash";
import Swal from "sweetalert2";
import moment from "moment";
import { mapState, mapActions, mapGetters } from "vuex";

import { db, firestore } from "@/db";
import { claim_orders_for_delivery } from "@/requests";

export default {
  data() {
    return {
      moment,

      collaboration_help_dialog: false,
      show_collab_deliveries: false,

      active_coord: null,
      selected_date: moment().format("YYYY-MM-DD"),
      selected_orders: [],
      current_route: {},
      current_directions: [],
      selected_transport_mode: "BICYCLING",
      transport_mode: [
        { name: "Bicycle", value: "BICYCLING", icon: "mdi-bicycle-basket" },
        { name: "Driving", value: "DRIVING", icon: "mdi-car-side" },
        { name: "Walking", value: "WALKING", icon: "mdi-walk" },
      ],
    };
  },
  watch: {
    show_collab_deliveries: function (checked) {
      this.clear_map();

      if (checked) {
        this.bind_collaboration_orders();
      } else {
        this.unbind_collaboration_orders();
      }
    },
    collaboration_orders: function (orders) {
      this.filter_selected_orders__collab_orders(orders);
    },
    orders: function (orders) {
      this.filter_selected_orders__shop_orders(orders);
    },
  },
  computed: {
    ...mapState(["server_url"]),
    ...mapGetters("AdminStore", [
      "shop_details_for_order_collaboration",
      "orders",
    ]),
    ...mapState("AdminStore", ["shop", "shop_id", "collaboration_orders"]),
    orders_for_delivery: function () {
      if (this.current_route.length > 0) {
        return [];
      }
      return this.orders.filter(
        (o) =>
          o.service === "delivery" &&
          o.fulfilment_date &&
          moment(this.selected_date).format("YYYY-MM-DD") ===
            moment(o.fulfilment_date, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
    },
    business_cords: function () {
      if (this.shop.geolocation) {
        return {
          lat: this.shop.geolocation.lat,
          lng: this.shop.geolocation.lng,
        };
      }
      return null;
    },
    is_collab_delivery: function () {
      return this.selected_orders.filter((o) => o.shop).length > 0;
    },
    collab_shop_markers: function () {
      let markers = {};
      let orders = this.selected_orders;
      let shops = orders
        .filter((o) => o?.shop?.id !== this.shop_id)
        .map((o) => Object.assign({}, o.shop));

      shops = uniqBy(shops, (s) => s.id);

      for (let shop of shops) {
        let coord = `${shop.lat};${shop.lng}`;
        markers[coord] = shop.name;
      }

      return markers;
    },
    selected_collaboration_orders_ids: function () {
      return this.selected_orders.filter((o) => o.shop).map((o) => o.id);
    },
    selected_shop_order_ids: function () {
      return this.selected_orders.filter((o) => !o.shop).map((o) => o.id);
    },
    markers: function () {
      if (Object.keys(this.current_route).length) {
        return this.current_route;
      }
      let markers = {};
      let collaboration_orders = this.collaboration_orders;
      let orders = this.orders_for_delivery;

      if (this.show_collab_deliveries && collaboration_orders) {
        const collab_orders_ids = collaboration_orders.map((o) => o.order_id);
        orders = orders.filter((o) => !collab_orders_ids.includes(o.id));
        orders = orders.concat(collaboration_orders);
      }

      for (let order of orders) {
        let coord = `${order.delivery.geolocation.lat};${order.delivery.geolocation.lng}`;
        if (markers[coord]) {
          markers[coord].push(order);
        } else {
          markers[coord] = [order];
        }
      }

      return markers;
    },
    waypoints: function () {
      let unique_locations = [
        ...new Set(
          this.selected_orders.map(
            (o) => `${o.delivery.geolocation.lat};${o.delivery.geolocation.lng}`
          )
        ),
      ];

      return [
        ...unique_locations.map((l) => ({
          location: {
            lat: Number(l.split(";")[0]),
            lng: Number(l.split(";")[1]),
          },
        })),
      ];
    },
    number_of_orders_to_claim: function () {
      let orders = this.orders_for_delivery;

      if (this.show_collab_deliveries && this.collaboration_orders) {
        const collab_orders_ids = this.collaboration_orders.map(
          (o) => o.order_id
        );
        orders = orders.filter((o) => !collab_orders_ids.includes(o.id));
        orders = orders.concat(this.collaboration_orders);
      }
      return orders.length > 0 ? orders.length : "No";
    },
  },
  methods: {
    ...mapActions("AdminStore", [
      "bind_collaboration_orders",
      "unbind_collaboration_orders",
    ]),
    marker_click_handler(coord, orders) {
      const update_selected_orders = function (idx) {
        let hit_idx = this.selected_orders.findIndex(
          (o) => o.id === orders[idx].id
        );
        if (hit_idx > -1) {
          this.selected_orders.splice(hit_idx, 1);
        } else {
          this.selected_orders.push(orders[idx]);
        }
      };
      if (orders.length === 1) {
        update_selected_orders.call(this, 0);
        return;
      } else {
        this.active_cords = coord;
        for (let idx in orders) {
          update_selected_orders.call(this, idx);
        }
      }
    },
    handle_order_selection(order) {
      let idx = this.selected_orders.findIndex((o) => o.id === order.id);
      if (idx > -1) {
        this.selected_orders.splice(idx, 1);
      } else {
        this.selected_orders.push(order);
      }
    },
    get_pin_label(orders) {
      let no = 0;
      for (let o of orders) {
        if (this.selected_orders.indexOf(o) > -1) {
          no += 1;
        }
      }
      if (orders.length > 1) {
        return {
          text:
            no > 0
              ? `${no}/${orders.length.toString()}`
              : orders.length.toString(),
          color: "rgb(254, 254, 254)",
        };
      }
    },
    set_pin_icon(orders) {
      if (!orders) return;
      if (orders.every((o) => this.selected_orders.indexOf(o) > -1)) {
        return require(`@/assets/selected-pin.png`);
      } else {
        return require(`@/assets/pin.png`);
      }
    },
    set_card_title() {
      let selected_orders = this.selected_orders;

      if (Object.keys(this.current_route).length > 0) {
        return `Plan Route with ${selected_orders.length} Orders`;
      } else if (selected_orders.length === 0) {
        return "Route Planner";
      } else {
        return `New Route for ${selected_orders.length} Orders`;
      }
    },
    filter_selected_orders__collab_orders(available_collab_orders) {
      let available_collab_orders_ids = available_collab_orders.map(
        (o) => o.order_id
      );
      this.selected_orders = this.selected_orders.filter(
        (o) => !o.shop || available_collab_orders_ids.includes(o.order_id)
      );
    },
    filter_selected_orders__shop_orders(available_shop_orders) {
      let available_shop_orders_ids = available_shop_orders.map(
        (o) => o.order_id
      );
      this.selected_orders = this.selected_orders.filter(
        (o) => o.shop || available_shop_orders_ids.includes(o.id)
      );
    },
    async create_route() {
      this.loading = true;
      const user_name = await firebase.auth().currentUser.displayName;
      const { value: name } = await Swal.fire({
        title: "Plan route",
        input: "text",
        inputValue: `${moment().format("DD-MM")}/`,
        text: `Name your route`,
        confirmButtonText: "Save",
        confirmButtonColor: "#54c4c9",
        showCancelButton: true,
      });

      if (!name) return;

      const collaboration_order_ids = this.selected_collaboration_orders_ids;
      const shop_orders_ids = this.selected_shop_order_ids;
      const route = {
        user_name,
        orders: this.format_orders_for_route(this.selected_orders),
        name: name,
        directions: this.current_directions,
        status: "planned",
        created_at: firestore.FieldValue.serverTimestamp(),
        updated_at: firestore.FieldValue.serverTimestamp(),
        mode: this.selected_transport_mode,
      };

      try {
        await claim_orders_for_delivery({
          collaboration_order_ids,
          shop_orders_ids,
          shop_name: this.shop.name,
          shop_id: this.shop_id,
        });

        await db
          .collection("shops")
          .doc(this.shop_id)
          .collection("routes")
          .add(route);

        this.clear_map();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async clear_map(options = { map: true, selected_orders: true }) {
      if (options.map) {
        this.current_route = [];
        if (this.directionsDisplay) {
          this.directionsDisplay.set("directions", null);
        }
      }
      if (options.selected_orders) {
        this.selected_orders = [];
      }
    },
    async get_route() {
      let google = await this.$gmapApiPromiseLazy();

      this.directionsService = new google.maps.DirectionsService();
      this.directionsDisplay = new google.maps.DirectionsRenderer();
      this.directionsDisplay.setMap(this.$refs.gmap.$mapObject);

      var ref = this;

      function get_route_callback(response, status) {
        if (status === "OK") {
          ref.current_route = {};
          ref.directionsDisplay.setDirections(response);
          var route = response.routes[0];

          for (let leg of route.legs) {
            let coord = `${leg.start_location.lat()};${leg.start_location.lng()}`;
            ref.current_directions.push(leg.end_address);

            ref.current_route[coord] = [
              {
                start: leg.start_address,
                end: leg.end_address,
                distance: leg.distance.text,
              },
            ];
          }
        } else {
          Swal.fire({
            title: "Oops...",
            text: `There was a problem with generating route. Please check selected orders and try again.`,
            confirmButtonText: "Close",
            confirmButtonColor: "#4caf50",
          });
        }
      }

      ref.directionsService.route(
        {
          origin: this.business_cords,
          waypoints: this.waypoints,
          travelMode: ref.selected_transport_mode,
          destination: this.business_cords,
          optimizeWaypoints: true,
        },
        get_route_callback
      );
    },
    format_orders_for_route(orders) {
      return orders.map((o) => {
        if (o.shop) return o;
        return {
          ...o,
          order_id: o.id,
          shop: this.shop_details_for_order_collaboration,
        };
      });
    },
  },
};
</script>

<style lang="scss">
// This is very specific selector to make GmapMarker
// label look preety. It selects label by specific shade
// of white color. Please do not judge!
.header-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.gm-style
  > div
  > div
  > div
  > div
  > div
  > div
  > div[style*="color: rgb(254, 254, 254)"] {
  background: blue;
  border-radius: 50%;
  line-height: 20px;
  height: 20px;
  width: 20px;
  font-size: 12px !important;
  text-align: center;
  font-weight: 700;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.marker-info-window {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  margin-right: 10px;
  cursor: pointer;
  padding: 0.5rem 0.7rem;
  border-radius: 8px;
  transition: background 0.125s ease-in-out;

  &:hover {
    background-color: #eee;
  }

  // &:first-of-type {
  //   margin-top: 1rem;
  // }

  &:last-of-type {
    margin-bottom: 0;
  }

  .img-responsive {
    max-width: 50px;
    height: auto;
  }

  p {
    margin: 0;
  }
}
</style>
