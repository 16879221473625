<template>
  <v-dialog
    v-model="show"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="$vuetify.breakpoint.smAndDown ? '' : '820px'"
    @keydown.esc="show = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        rounded
        v-bind="attrs"
        v-on="on"
        class="mr-4"
      >
        New Group booking
      </v-btn>
    </template>
    <v-card class="action-modal">
      <v-card-title
        class="px-4 py-3 primary font-weight-bold secondary--text text-center"
      >
        <span
          >New Group Booking on
          {{ moment(_selected_date).format("DD/M") }}</span
        >
        <v-progress-linear
          v-if="is_fetching_options || is_sending"
          indeterminate
          color="primary lighten-1"
          style="top: 3.7rem"
          class="mb-0"
          absolute
          top
        />
      </v-card-title>
      <v-card-text style="position: relative">
        <v-container v-if="view === 1" class="action-modal-content px-5 pb-5">
          <v-alert type="info" color="primary" border="left" colored-border>
            <span class="dark-grey--text font-weight-medium text-center">
              Creating group booking does not obey any rules. It's up to you to
              make sure you can accomodate incoming guests and select tables
              manually.
            </span>
          </v-alert>
          <div class="d-flex justify-space-between">
            <p class="font-weight-bold mb-2">Select the tables</p>
            <p class="font-weight-bold mb-1">Capacity: {{ capacity }}</p>
          </div>
          <div v-for="(zone, i) in zones" :key="`zone_${i}`" class="mb-2">
            <p class="mb-1">
              {{ zone.name }}
            </p>

            <div
              class="d-flex flex-wrap table-container"
              style="gap: 0.5rem; justify-content: flex-start"
            >
              <div
                v-for="(table, j) in tables_by_zone[zone.id]"
                :key="`table_${i}_${j}`"
                class="--table"
                :class="{ '--active': selected_tables_ids.includes(table.id) }"
                @click="on_table_click(table)"
              >
                <p class="text-body-1">
                  {{ table.name }} ({{ table.capacity }})
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-end">
            <v-btn
              class="mr-0"
              color="primary"
              :disabled="selected_tables.length < 2"
              rounded
              @click="view = 2"
              >Next</v-btn
            >
          </div>
        </v-container>
        <v-container v-if="view === 2" class="action-modal-content px-5 pb-5">
          <p class="font-weight-bold mb-2">Capture booking details</p>
          <v-form v-model="form_valid" class="d-flex mb-6" style="gap: 2rem">
            <v-date-picker
              class="new-booking-calendar br-16"
              v-model="_selected_date"
              show-current="false"
              first-day-of-week="1"
              color="primary"
              :header-date-format="
                (v) => {
                  return moment(v, `YYYY-MM`).format(`MMMM YYYY`);
                }
              "
              no-title
              :disabled="is_sending"
            />
            <div>
              <v-text-field
                type="text"
                class="guest-detail-text"
                v-model="new_booking.user_name"
                prepend-icon="$account"
                label="Guest Name *"
                dense
                single-line
                maxlength="40"
                :rules="[field_req]"
                required
                :disabled="is_sending || is_editing"
              />

              <v-text-field
                type="text"
                class="guest-detail-text"
                v-model="new_booking.user_email"
                prepend-icon="$mail"
                label="Guest Email *"
                dense
                single-line
                maxlength="40"
                :rules="[field_req, valid_email]"
                required
                :disabled="is_sending || is_editing"
              />

              <v-text-field
                type="number"
                class="guest-detail-text remove-spinner"
                v-model="new_booking.user_phone_no"
                prepend-icon="$phone"
                dense
                label="Guest Phone No *"
                single-line
                maxlength="40"
                :rules="phone_no_rules"
                required
                :disabled="is_sending || is_editing"
              />

              <v-text-field
                type="number"
                class="guest-detail-text remove-spinner"
                v-model="new_booking.guest_count"
                prepend-icon="mdi-counter"
                dense
                label="Number of people *"
                single-line
                maxlength="40"
                :rules="[field_req, is_number]"
                required
                :disabled="is_sending || is_editing"
              />
              <v-select
                class="guest-detail-text"
                dense
                :disabled="is_sending || is_editing"
                :items="time_range('00:00', '24:00', settings.interval * 60)"
                v-model="new_booking.start_at"
                label="Start at *"
                :rules="[field_req]"
                required
              ></v-select>

              <v-select
                class="guest-detail-text"
                dense
                :disabled="is_sending || is_editing"
                :items="time_range('00:00', '24:00', settings.interval * 60)"
                v-model="new_booking.end_at"
                label="End at *"
                :rules="[field_req]"
                required
              ></v-select>

              <v-textarea
                v-model="new_booking.notes"
                class="notes-field mr-11"
                label="Notes:"
                no-resize
                background-color="#f0f0f0"
              />
            </div>
          </v-form>
          <div class="d-flex justify-space-between">
            <v-btn color="primary" rounded @click="view = 1">Back</v-btn>
            <v-btn
              color="primary"
              :disabled="selected_tables.length < 2 || !form_valid"
              rounded
              @click="create_group_booking"
              >Create booking</v-btn
            >
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";
import range from "lodash/range";
import {
  field_req,
  valid_email,
  is_number,
  length_max,
} from "@/utils/form_val_rules";
import { BOOKING_STATUS_ITEMS } from "@/shared/const.js";
import { create_group_table_booking } from "@/requests";
import {
  get_HHmm_time_as_seconds,
  get_seconds_as_HHmm,
} from "@/utils/date_utils";

export default {
  name: "NewGroupBookingDialog",
  props: {},
  data() {
    return {
      show: false,
      selected_tables: [],
      view: 1,

      moment,
      BOOKING_STATUS_ITEMS,
      field_req,
      valid_email,
      is_number,
      show_edit_snackbar: true,
      form_valid: false,
      accept_marketing: false,
      selected_date: null,
      selected_guest_count: null,
      selected_start_time: null,
      selected_brand: null,
      new_booking_notes: null,
      new_booking: {
        user_name: "",
        user_email: "",
        user_phone_no: "",
        start_at: "",
        end_at: "",
        guest_count: "",
        notes: "",
      },
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("ShopStore", ["shop"]),
    ...mapState("TableBookingStore", [
      "settings",
      "show_new_booking",
      "is_sending",
      "is_fetching_options",
      "tables",
      "zones",
      "selected_booking_date",
    ]),
    ...mapGetters("TableBookingStore", ["tables_by_zone"]),
    selected_tables_ids() {
      return this.selected_tables.map((t) => t.id);
    },
    capacity() {
      return this.selected_tables.reduce((a, b) => a + b.capacity, 0);
    },
    _selected_date: {
      get() {
        return this.selected_date || this.selected_booking_date;
      },
      set(date) {
        this.selected_date = date;
      },
    },
    _selected_brand: {
      get() {
        return this.selected_brand || this.shop.brands[0].id;
      },
      set(brand) {
        this.selected_brand = brand;
      },
    },
    is_editing() {
      return this.selected_booking != null;
    },
    _available_brands() {
      return this.shop.brands.map((brand) => {
        return { text: brand.name, value: brand.id };
      });
    },
    phone_no_rules() {
      return this.walkIn
        ? [is_number, (v) => length_max(v, 11)]
        : [field_req, is_number, (v) => length_max(v, 11)];
    },
  },
  methods: {
    ...mapActions("TableBookingStore", ["select_booking"]),
    on_table_click(table) {
      const idx = this.selected_tables.indexOf(table);
      if (idx > -1) {
        this.selected_tables.splice(idx, 1);
      } else {
        this.selected_tables.push(table);
      }
    },
    time_range(from, to, interval) {
      if (!from || !to) return;
      const seconds_in_15_mins = interval || 900;
      const available_times_in_seconds = range(
        get_HHmm_time_as_seconds(from),
        get_HHmm_time_as_seconds(to) + (interval || 900),
        seconds_in_15_mins
      );
      const midnight = 86400; // 24:00 in seconds
      const last_time = available_times_in_seconds.length - 1;

      if (available_times_in_seconds[last_time] === midnight) {
        // Change last time to "23:59"
        available_times_in_seconds[last_time] = midnight - 1;
      }

      return available_times_in_seconds.map(get_seconds_as_HHmm);
    },
    async create_group_booking() {
      const payload = {
        ...this.new_booking,
        shop_id: this.shop_id,
        brand_id: this._selected_brand,
        table_ids: this.selected_tables_ids,
      };
      payload.start_at = [this._selected_date, payload.start_at + ":00"].join(
        "T"
      );
      payload.end_at = [this._selected_date, payload.end_at + ":00"].join("T");

      try {
        const { data: booking } = await create_group_table_booking(payload);
        this.select_booking(booking);
        this.show = false;
        this.view = 1;
        this.new_booking = {
          user_name: "",
          user_email: "",
          user_phone_no: "",
          start_at: "",
          end_at: "",
          guest_count: "",
          notes: "",
        };
        this.selected_tables = [];
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.--table {
  border: 2px solid var(--v-light-grey-base);
  border-radius: 5px;
  display: inline;
  max-width: 200px;
  padding: 5px;
  cursor: pointer;
  &.--active {
    background-color: var(--v-primary-base);
    color: white;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    border-color: var(--v-primary-base);
  }
  p {
    margin: 0;
  }
}
.guest-detail-text ::v-deep {
  max-width: 250px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: 100%;
  }
}
.v-input--selection-controls {
  margin-top: 0;
}
.notes-field ::v-deep {
  border-radius: 5px;
  width: 300px;
  * {
    max-height: 82px;
    font-size: 14px;
  }
  .v-input__slot {
    padding: 0 8px;
    margin: 0;
    &::before {
      border: none;
    }
  }
}
.new-booking-calendar {
  grid-area: calendar;
  border: 2px solid var(--v-light-grey-base);
  z-index: 10;
  .v-picker__body {
    max-width: 276px;
    .v-date-picker-header {
      max-width: 276px;
      margin: 0 12px;
      padding: 0;
      .v-icon {
        color: var(--v-primary-base);
      }
      button {
        color: var(--v-dark-grey-base) !important;
        &:hover {
          color: var(--v-primary-base) !important;
        }
      }
    }
    .v-date-picker-table--date th {
      padding-top: 2px;
    }
    .v-date-picker-table {
      max-width: 276px;
      padding: 0;
      th {
        color: var(--v-dark-grey-base);
        font-size: 14px;
        font-weight: normal;
      }
      tr {
        td {
          button {
            background-color: white !important;
            font-size: 14px;
            font-weight: 500;
            .v-btn__content {
              color: var(--v-dark-grey-base);
            }
            &.v-btn--disabled {
              .v-btn__content {
                color: var(--v-completed-base);
              }
            }
            &.v-btn--active {
              border: 2px solid;
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}

.table-container {
  display: flex;
  flex-wrap: wrap; /* Wraps to the next row when needed */
  justify-content: flex-start; /* Aligns items to the start */
  gap: 0.5rem; /* Adjust spacing between items */
}

.--table {
  flex: 0 0 calc(100% / 15 - 0.5rem); /* Divide the width equally for 15 items */
  box-sizing: border-box; /* Includes padding and border in the width calculation */
  border: 1px solid var(--v-light-grey-base);
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.--table.--active {
  background-color: var(--v-primary-base);
  color: white;
}

.--table p {
  margin: 0;
  font-size: 14px;
}
</style>
