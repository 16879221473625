<template>
  <v-navigation-drawer
    :value="$vuetify.breakpoint.smAndUp || drawer"
    app
    :mini-variant="$vuetify.breakpoint.smAndUp && !drawer"
    mini-variant-width="66"
    fixed
    floating
    width="260"
    mobile-breakpoint="960"
    :hide-overlay="$vuetify.breakpoint.smAndUp"
    persistent
    @input="(v) => (drawer = v)"
    color="secondary"
  >
    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="secondary" contain>
          <v-img v-if="shop && shop.logo" :src="shop.logo" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1 font-weight-bold"
            v-text="shop && shop.name"
          />
          <v-list-item-subtitle v-if="is_directory_shop">
            Directory Shop Only
          </v-list-item-subtitle>
          <v-list-item-subtitle
            >Connection Status
            <span
              :class="{
                'primary--text': is_network_online,
                'red--text': !is_network_online,
              }"
            >
              • {{ is_network_online ? "Online" : "Offline" }}</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list rounded expand nav>
      <template v-for="(el, idx) in nav_items">
        <template v-if="!el.hidden">
          <v-list-item
            dense
            class="nav-el-item"
            v-if="!el.children"
            :key="`root_el_${idx}`"
            :to="el.to"
            active-class="active-nav-el"
            v-ripple="false"
          >
            <v-list-item-icon>
              <v-icon v-text="el.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title
              v-if="display_titles"
              class="font-weight-bold"
              v-html="el.title"
            />
          </v-list-item>

          <v-list-group
            v-else
            dense
            class="nav-el-item v-list-item--dense"
            :key="`root_el_${idx}`"
            :group="el.group"
          >
            <template
              v-slot:activator
              dense
              class="nav-el-item"
              v-ripple="false"
            >
              <v-list-item-icon>
                <v-badge
                  :value="$vuetify.breakpoint.smAndUp && !drawer"
                  class="nav-badge"
                  overlap
                  offset-x="16px"
                  offset-y="12px"
                  bottom
                  icon="mdi-chevron-down"
                >
                  <v-icon v-text="el.icon"></v-icon>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-title
                v-if="display_titles"
                class="font-weight-bold"
                v-html="el.title"
              />
            </template>

            <v-list-item
              dense
              class="nav-el-item"
              :class="{
                'not-shown': child_el.hidden,
                'ml-7': drawer,
              }"
              active-class="active-nav-el"
              v-ripple="false"
              v-for="(child_el, i) in el.children"
              :key="i"
              :to="child_el.to"
            >
              <v-list-item-icon class="pl-2">
                <v-icon v-text="child_el.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-if="display_titles">
                <v-badge
                  v-if="child_el.badge"
                  class="mt-0"
                  dot
                  color="accent"
                  inline
                >
                  <span v-html="child_el.title" class="mr-2" />
                </v-badge>
                <span v-else v-html="child_el.title" />
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
    </v-list>
    <v-list nav rounded class="other-drawer-items">
      <v-list-item
        dense
        class="nav-el-item"
        :class="{
          'not-shown': !staff_accessible_paths.includes('/settings'),
        }"
        to="/settings"
        active-class="active-nav-el"
        v-ripple="false"
      >
        <v-list-item-icon>
          <v-icon v-text="'$settings-cog'"></v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <v-badge
            :value="!is_required_settings_complete"
            class="mt-0"
            dot
            color="accent"
            inline
          >
            <span class="mr-2">Settings</span>
          </v-badge></v-list-item-title
        >
      </v-list-item>
      <v-menu
        :close-on-content-click="true"
        offset-y
        top
        content-class="white br-16"
      >
        <template v-slot:activator="{ on }">
          <v-list-item
            dense
            class="nav-el-item"
            active-class="active-nav-el"
            v-ripple="false"
            v-on="on"
          >
            <v-list-item-avatar contain>
              <Avatar
                variant="beam"
                :size="40"
                :colors="[
                  '#FBEAC7',
                  '#54C4C9',
                  '#7ACB9F',
                  '#A582C7',
                  '#FF8080',
                ]"
                :name="
                  logged_in_staff ? logged_in_staff.name : user.displayName
                "
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="test-h5"
                >{{
                  logged_in_staff ? logged_in_staff.name : user.displayName
                }}
                <v-icon small style="top: -1px"
                  >mdi-chevron-up</v-icon
                ></v-list-item-title
              >
              <v-list-item-subtitle class="text-h6"
                >Log Out</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item
          dense
          v-for="(el, idx) in other_nav_items"
          class="nav-el-item"
          :class="{
            'not-shown': el.hidden,
          }"
          :key="`other_el_${idx}`"
          :to="el.to"
          :href="el.href"
          active-class="active-nav-el"
          v-ripple="false"
        >
          <v-list-item-icon>
            <v-icon v-text="el.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-html="el.title" />
        </v-list-item>
      </v-menu>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import Avatar from "vue2-boring-avatars";

export default {
  name: "DashboardCoreDrawer",
  components: {
    Avatar,
  },
  data() {
    return {
      navbar: true,
      has_internet_connection: true,
      inpersonshops: [
        // "the-lazy-dog",
        "makers-shop",
        // "windmill-hill-city-farm",
        // "grain-barge",
        // "salt--malt-wapping-wharf",
        "vikas-software",
        "brand-new",
      ],
    };
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    ...mapState("AdminStore", ["shop", "shop_id"]), // modified by vikas
    ...mapState("StaffStore", ["logged_in_staff"]),
    ...mapState("RealtimeStore", { realtime_status: "status" }),
    ...mapGetters("StaffStore", [
      "logged_in_staff_first_name",
      "staff_accessible_paths",
    ]),
    ...mapGetters("AdminStore", [
      "has_dining_service",
      "has_retail_service",
      "dining_orders_length",
      "retail_orders_length",
      "is_directory_shop",
      "is_required_settings_complete",
    ]),
    ...mapGetters("TableBookingStore", [
      "is_table_booking_enabled",
      "table_booking_state",
    ]),
    ...mapState("UserStore", ["user", "is_super_admin", "is_community_admin"]),
    is_network_online() {
      if (!this.has_internet_connection) return false;
      if (this.realtime_status !== "connected") return false;
      return true;
    },
    other_nav_items: function () {
      return [
        {
          title: "Staff",
          icon: "mdi-account-group",
          to: "/staff",
          hidden: !this.staff_accessible_paths.includes("/staff"),
        },
        // {
        //   title: "Settings",
        //   icon: "$settings-cog",
        //   to: "/settings",
        //   hidden: !this.staff_accessible_paths.includes("/settings"),
        // },
        {
          title: "Super Admin",
          icon: "mdi-account-lock",
          to: "/admin/shops",
          hidden: !this.is_super_admin && !this.is_community_admin,
        },
        {
          title: "Help",
          icon: "mdi-help-circle-outline",
          href: "http://support.wearelocals.co.uk/",
        },
        {
          title: `Log Out ${this.logged_in_staff_first_name}`,
          icon: "mdi-logout",
          to: this.logged_in_staff ? "/staff_login" : "/logout",
        },
      ];
    },
    nav_items: function () {
      return [
        {
          title: this.retail_orders_length
            ? `
            Retail <span class='amount-indicator accent ml-2'>${this.retail_orders_length}</span></span>
          `
            : "Retail",
          icon: "$retail",
          hidden: !this.has_retail_service,
          group: "retail|deliveries|planner",
          children: [
            {
              title: this.retail_orders_length
                ? `
            My Orders <span class='amount-indicator accent ml-2'>${this.retail_orders_length}</span></span>
          `
                : "My Orders",
              to: "/retail",
              icon: "$retail-orders",
              hidden: !this.staff_accessible_paths.includes("/retail"),
            },
            {
              title: "Delivery Planner",
              to: "/planner",
              icon: "$delivery-planner",
              hidden: !this.staff_accessible_paths.includes("/planner"),
            },
            {
              title: "Planned Deliveries",
              to: "/deliveries",
              icon: "$deliveries",
              hidden: !this.staff_accessible_paths.includes("/deliveries"),
            },
          ],
        },
        {
          title:
            this.has_dining_service && this.dining_orders_length > 0
              ? `
            My Orders <span class='amount-indicator accent ml-2'>${this.dining_orders_length}</span>
          `
              : "My Orders",
          icon: "$my-orders",
          to: "/hospitality",
          hidden:
            !this.has_dining_service ||
            !this.staff_accessible_paths.includes("/hospitality"),
        },
        {
          title: "Table Booking",
          icon: "$table",
          hidden:
            !this.is_table_booking_enabled ||
            !this.staff_accessible_paths.some(
              (path) => path.startsWith("/booking") || path.startsWith("/today")
            ),
          group: "booking-timeline|management|booking|reviews",
          children: [
            {
              title: "My Bookings",
              to: "/booking-timeline",
              icon: "mdi-calendar-today",
              hidden:
                !this.staff_accessible_paths.includes("/booking-timeline"),
            },
            {
              title: "Manage All Bookings",
              to: "/management",
              icon: "mdi-account-cog",
              hidden: !this.staff_accessible_paths.includes("/management"),
            },
            {
              title: "Customer Reviews",
              to: "/reviews",
              icon: "mdi-star",
              hidden: true,
            },
            {
              title: "Settings",
              to: "/booking",
              icon: "mdi-cog",
              badge: !Object.values(this.table_booking_state).every(Boolean),
              hidden: !this.staff_accessible_paths.includes("/booking"),
            },
          ],
        },
        {
          title: "Products",
          icon: "$products",
          group:
            "products|categories|inpersonpayments|inpersonorders|cookeryclasses", // Modified by vikas
          hidden:
            !this.staff_accessible_paths.includes("/products") &&
            !this.staff_accessible_paths.includes("/categories"),
          children: [
            {
              group: "products",
              title: "My Product List",
              to: "/products",
              icon: "$products-list",
              hidden: !this.staff_accessible_paths.includes("/products"),
            },
            //  added by vikas in person payment
            {
              group: "products",
              title: "In Person Payments",
              to: "/inpersonpayments",
              icon: "$hand-money",
              hidden: !this.inpersonshops.includes(this.shop_id), // Modified by vikas
            },
            //  added by vikas in person payment
            //  added by vikas in person orders on 5 dec
            {
              group: "products",
              title: "In Person sales",
              to: "/inpersonorders",
              icon: "$retail-orders",
              hidden: !this.inpersonshops.includes(this.shop_id), // Modified by vikas
            },
            //  added by vikas in person orders on 5 dec
            // Added by vikas on 19th of dec for widget
            {
              group: "products",
              title: "cookery classes",
              to: "/cookeryclasses",
              icon: "$retail-orders",
              hidden: !this.inpersonshops.includes(this.shop_id), // Modified by vikas
            },
            // Added by vikas on 19th of dec for widget
            {
              group: "products",
              title: "Product Categories",
              to: "/categories",
              icon: "$categories",
              hidden: !this.staff_accessible_paths.includes("/categories"),
            },
          ],
        },
        {
          title: "Vouchers",
          icon: "$vouchers",
          to: "/vouchers",
          hidden: !this.staff_accessible_paths.includes("/vouchers"),
        },
        {
          title: "Advertising",
          icon: "$advertising",
          to: "/advertising",
          hidden: !this.staff_accessible_paths.includes("/advertising"),
        },
        {
          title: "Reports",
          icon: "$reports",
          to: "/reports",
          hidden: !this.staff_accessible_paths.includes("/reports"),
        },
      ];
    },
    display_titles() {
      if (this.$vuetify.breakpoint.smAndUp) return this.drawer;
      return true;
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
  mounted() {
    window.addEventListener(
      "offline",
      () => (this.has_internet_connection = false)
    );
    window.addEventListener(
      "online",
      () => (this.has_internet_connection = true)
    );
  },
};
</script>

<style lang="scss">
.other-drawer-items {
  position: absolute;
  bottom: 0;
  width: 100%;
}

:not(.v-navigation-drawer--mini-variant)
  .v-list-item__icon:not(.v-list-group__header__append-icon) {
  margin-right: 16px !important;
}

.nav-el-item {
  .v-list-item__title {
    color: #666;
  }
  .v-list-item__icon {
    .v-icon {
      transform: scale(0.8);
    }
  }
}
.nav-badge {
  .v-badge__badge {
    background-color: transparent !important;
    transform: scale(0.6);
    i {
      color: #666;
    }
  }
}

.active-nav-el:not(.v-list-group__header) {
  &.theme--light {
    &.v-list-item--active {
      &::before {
        opacity: 0 !important;
      }
    }
    background-color: #dff4f5;
    .v-list-item__title {
      color: #2ed7de !important;
    }
    .v-list-item__icon {
      i {
        color: #2ed7de;
      }
      svg g g path {
        fill: #2ed7de !important;
        stroke: #2ed7de !important;
      }
      svg g path:not(.fillable),
      svg > path,
      svg > circle {
        stroke: #2ed7de !important;
      }
      svg .fillable {
        fill: #2ed7de !important;
      }
      svg .strokable {
        stroke: #2ed7de !important;
      }
    }
  }
}
</style>
