<template>
  <v-container fluid tag="section">
    <v-container fluid tag="section" class="py-0">
      <v-row class="secondary px-4 br-8 pt-0 pb-2">
        <!-- Product List Section -->
        <v-col cols="12">
          <!-- Filter and Search Section -->
          <v-row class="mb-4" align="center">
            <v-combobox
              v-model="category_filter"
              :items="categories_name_list"
              item-text="name"
              item-value="value"
              label="Select Category"
              hide-details
              clearable
            ></v-combobox>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="searchQuery"
              append-icon="mdi-magnify"
              label="Search Cookery Classes"
              single-line
              hide-details
              dense
            ></v-text-field>
          </v-row>

          <!-- Product Grid with Pagination -->
          <v-row>
            <v-col
              v-for="product in paginatedProducts"
              :key="product.id"
              cols="6"
              md="2"
            >
              <v-card class="product-card" height="200px">
                <v-img :src="product.image" height="120px"></v-img>
                <v-card-title class="product-name truncate-title">
                  {{ product.name }}
                </v-card-title>
                <!-- <v-card-subtitle class="product-price">
                  {{ product.price | currency }}
                </v-card-subtitle> -->
              </v-card>
            </v-col>
          </v-row>

          <!-- Pagination -->
          <v-row justify="center" class="mt-4">
            <v-pagination
              v-model="currentPage"
              :length="pageCount"
              @input="onPageChange"
            ></v-pagination>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="show_snackbar" top right color="red" timeout="6000">
      <span class="black--text font-weight-bold">
        {{ snackbarcustommessage }}
      </span>
    </v-snackbar>

    <v-snackbar
      v-model="show_snackbar_green"
      top
      right
      color="green"
      timeout="2000"
    >
      <span class="black--text font-weight-bold">
        {{ snackbarcustommessage }}
      </span>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "products",
  data() {
    return {
      category_filter: null,
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 36, // 6x6 matrix
      show_snackbar: false,
      snackbarcustommessage: "",
      show_snackbar_green: false,
    };
  },
  computed: {
    ...mapState("AdminStore", [
      "shop",
      "shop_id",
      "product_categories",
      "shop_products_categories",
    ]),
    ...mapGetters("AdminStore", ["products", "all_product_categories"]),

    categories_name_list: function () {
      if (!this.shop_products_categories || !this.all_product_categories)
        return [];
      return Object.keys(this.shop_products_categories)
        .map((id) => this.all_product_categories[id])
        .sort();
    },

    filteredProducts() {
      let products = this.products;

      if (this.category_filter && this.category_filter !== "all") {
        products = products.filter(
          (product) => product.category === this.category_filter
        );
      }

      if (this.searchQuery) {
        products = products.filter((product) =>
          product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      return products;
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      return this.filteredProducts.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    },
  },
  watch: {
    category_filter() {
      this.currentPage = 1;
    },
    searchQuery() {
      this.currentPage = 1;
    },
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
    },
  },
  filters: {
    currency(value) {
      return `£ ${value.toFixed(2)}`;
    },
  },
};
</script>

<style scoped>
.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-name {
  font-weight: bold;
  font-size: 0.7rem;
}
.product-price {
  color: #666;
  padding-top: 10px;
  font-weight: bold;
  font-size: 0.9rem;
}
.truncate-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
</style>
