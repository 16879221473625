<template>
  <v-container fluid tag="section">
    <v-container fluid tag="section" class="py-0">
      <v-row class="secondary px-4 br-8 pt-0 pb-2">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by order/receipt number, customer or postcode"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="service_filter"
            :items="RETAIL_SERVICE_TYPES_ITEMS"
            label="Filter by Service"
            hide-details
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="status_filter"
            :items="Object.values(ORDER_STATUSES)"
            item-text="name"
            item-value="id"
            label="Filter by Status"
            hide-details
            multiple
            clearable
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="px-4 py-4 justify-space-between" no-gutters>
      <v-btn
        :disabled="!selected_orders.length"
        @click="display_bulk_update_dialog = true"
        rounded
        color="primary"
        >Update Orders Status</v-btn
      >
      <div>
        <DownloadCSV
          :source="orders_csv_format"
          :headers="headers_csv_format"
          file_name="orders"
          class="mr-6"
        />
        <v-btn
          :disabled="!has_orders_in_progress"
          @click="show_picking_list = true"
          color="primary"
          class="mr-0"
          rounded
          >Picking List</v-btn
        >
      </div>
    </v-row>
    <v-data-table
      v-model="selected_orders"
      show-select
      :headers="headers"
      :items="orders"
      :search="search"
      :sort-by.sync="sort_by"
      :sort-desc.sync="sort_desc"
      :items-per-page="10"
      item-key="id"
      @click:row="(order) => (selected_order = order)"
      class="orders br-8"
    >
      <template v-slot:item.receipt_ref="{ item }">{{
        item.receipt_ref || "-"
      }}</template>

      <template v-slot:item.created_at="{ item }">
        {{ format_created_at_date(item.created_at) }}
      </template>

      <template v-slot:item.service="{ item }">
        <div class="d-flex align-center">
          <v-icon v-text="`$${item.service}`" class="mr-2" />
          {{ format_service(item.service) }}
        </div>
      </template>

      <template v-slot:item.postcode="{ item }">{{
        (item.delivery && item.delivery.postcode) || "-"
      }}</template>

      <template v-slot:item.total="{ item }">
        <div class="text-center">{{ item.total.toFixed(2) }}</div>
      </template>

      <template v-slot:item.fulfilment_date="{ item }">
        <v-hover v-if="!item.is_shared" v-slot:default="{ hover }">
          <v-chip
            :class="[
              {
                'darken-4 text--darken-5': hover,
                'darken-1 text--darken-1': !hover,
              },
              'fulfilment-date-chip',
            ]"
            outlined
            :disabled="
              item.status.id === 'completed' ||
              (item.collaboration_status &&
                item.collaboration_status.status === 'claimed')
            "
            @click.stop.prevent="
              (show_confirm_fulfilment = true), (fulfilment_order = item)
            "
          >
            <v-icon v-text="'$chat'" small color="#666" class="mr-2" />
            <span class="chip-content">{{
              item.fulfilment_date
                ? format_fulfilment(item.fulfilment_date)
                : "Not confirmed"
            }}</span>
          </v-chip>
        </v-hover>
        <p v-else class="mb-0">
          {{
            item.fulfilment_date
              ? format_fulfilment(item.fulfilment_date)
              : "Not confirmed"
          }}
        </p>
      </template>

      <template v-slot:item.collaboration_status="{ item }">
        <CollaborationStatusCell :order="item" />
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip dark :class="`order-status-chip ${item.status}`">{{
          ORDER_STATUSES[item.status].name
        }}</v-chip>
      </template>

      <template v-slot:no-data>
        <p>No orders today</p>
      </template>
    </v-data-table>

    <BulkUpdateOrderStatus
      :disabled="!display_bulk_update_dialog"
      :orders="selected_orders"
      @close="display_bulk_update_dialog = false"
      @reset-selected-orders="selected_orders = []"
    />
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="yellow"
      top
      right
      transition="slide-x-reverse-transition"
    >
      <b class="black--text">Changes were saved successfully</b>
      <template v-slot:action>
        <v-icon color="black" @click="snackbar = false"
          >mdi-close-circle-outline</v-icon
        >
      </template>
    </v-snackbar>

    <ConfirmFulfilmentDate
      :visible="show_confirm_fulfilment"
      :order="fulfilment_order"
      :updating_fulfilment_date="updating_fulfilment_date"
      @confirm_fulfilment_date="confirm_fulfilment_date"
      @close="
        (show_confirm_fulfilment = false),
          (fulfilment_order = null),
          (selected_order = null)
      "
      @back_to_order="
        (selected_order = fulfilment_order),
          (show_confirm_fulfilment = false),
          (fulfilment_order = null)
      "
    />

    <PickingList
      :visible="show_picking_list"
      @close="show_picking_list = false"
    />

    <OrderDetails
      :order="fulfilment_order ? null : selected_order"
      @close="selected_order = null"
      @confirm_order="
        (show_confirm_fulfilment = true), (fulfilment_order = selected_order)
      "
    />
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { SHOP_SERVICES, ORDER_STATUSES } from "../dashboard_data";
import {
  SERVICE_TYPES_INFO,
  RETAIL_SERVICE_TYPES_ITEMS,
} from "@/shared/services";

import OrderDetails from "../components/OrderDetails";
import PickingList from "../components/PickingList";
import BulkUpdateOrderStatus from "@/views/dashboard/forms/BulkUpdateOrderStatus";
import CollaborationStatusCell from "../components/CollaborationStatusCell";
import ConfirmFulfilmentDate from "../components/ConfirmFulfilmentDate";
import DownloadCSV from "@/components/DownloadCSV";

import { update_orders } from "@/requests";

export default {
  name: "Orders",
  components: {
    BulkUpdateOrderStatus,
    PickingList,
    OrderDetails,
    CollaborationStatusCell,
    ConfirmFulfilmentDate,
    DownloadCSV,
  },
  data() {
    return {
      moment,

      ORDER_STATUSES,
      SHOP_SERVICES,
      SERVICE_TYPES_INFO,
      RETAIL_SERVICE_TYPES_ITEMS,

      search: "",
      sort_by: "order_no",
      sort_desc: true,

      order_id: null,
      selected_order: null,
      service_filter: null,

      status_filter: [],
      selected_orders: [],

      show_picking_list: false,
      display_bulk_update_dialog: false,

      show_confirm_fulfilment: false,
      updating_fulfilment_date: false,
      order: null,
      fulfilment_order: null,
      snackbar: false,

      headers_csv_format: {
        order_no: "Order #",
        status: "Status",
        receipt_ref: "Ref. #",
        created_at: "Date Created",
        fulfilment_date: "Service Date",
        // customer: "Customer",
        service: "Service",
        postcode: "Postcode",
        total: "Total (£)",
        total_vat: "VAT (£)",
      },
    };
  },
  computed: {
    ...mapGetters("AdminStore", ["orders"]),
    ...mapState("AdminStore", ["shop", "shop_id"]),
    headers() {
      return [
        {
          text: "Status",
          value: "status",
          filter: (value) => {
            if (!this.status_filter.length) {
              return true;
            }
            return this.status_filter.some((st) => st === value);
          },
          sort: (a, b) => {
            // Sort by order of statuses
            const status_order = Object.values(this.ORDER_STATUSES).map(
              (s) => s.name
            );
            return status_order.indexOf(a) - status_order.indexOf(b);
          },
          align: "center",
        },
        {
          text: "Order #",
          value: "order_no",
        },
        // {
        //   text: "Ref.",
        //   value: "receipt_ref",
        // },
        { text: "Created", value: "created_at", filterable: false },
        // {
        //   text: "Customer",
        //   value: "customer.name",
        // },
        // {
        //   text: "Service",
        //   value: "service",
        //   filter: (value) => {
        //     if (!this.service_filter) {
        //       return true;
        //     }
        //     return value === this.service_filter;
        //   },
        // },
        // {
        //   text: "Postcode",
        //   value: "postcode",
        // },
        { text: "Total (£)", value: "total", align: "right" },
        // { text: "Service Date", value: "fulfilment_date", align: "center" },
        { text: "Refund Order", value: "fulfilment_date", align: "center" },
        // {
        //   text: "Collab.",
        //   value: "collaboration_status",
        //   align: "center",
        //   filterable: false,
        // },
      ];
    },
    has_orders_in_progress() {
      if (!this.orders) return;

      return this.orders.some((order) => order.status === "processing");
    },
    table_title() {
      const no_orders = this.orders.length;

      if (no_orders === 0) return "Orders";
      if (no_orders === 1) return "You have 1 order";

      return `You have ${no_orders} orders`;
    },
    orders_csv_format() {
      return this.orders
        .map((o) => {
          return {
            order_no: o.order_no || "",
            status: o.status || "",
            receipt_ref: o.receipt_ref || "",
            created_at:
              moment.unix(o.created_at.seconds).format("DD/MM/YYYY hh:mm") ||
              "",
            fulfilment_date: o.fulfilment_date
              ? moment(o.fulfilment_date).format("DD/MM/YYYY")
              : "",
            // customer: o.customer.name || "",
            service: o.service.replace(/_/g, " ") || "",
            postcode: o.delivery?.postcode || "",
            total: o.total.toFixed(2) || "",
            total_vat: o.total_vat || Number(0).toFixed(2),
          };
        })
        .sort((a, b) => {
          if (a.order_no < b.order_no) return -1;
          if (a.order_no > b.order_no) return 1;
          return 0;
        });
    },
  },
  methods: {
    filter_by_search_term(serach_term, order) {
      if (this.search.trim() === "") {
        return true;
      }
      let term = this.search.toLowerCase();

      if (order.order_no.toString().startsWith(term)) return true;

      if (order.receipt_ref && order.receipt_ref.startsWith(term)) return true;
      if (order.postcode.startsWith(term)) return true;

      //if (order.customer.name.toLowerCase().includes(term)) return true;
      return false;
    },
    format_created_at_date(created_at) {
      // Although created_at is timestamp, firebase returns epoch
      if (!created_at) return;

      return moment.unix(created_at.seconds).format("DD/MM/YYYY HH:mm");
    },
    format_service(service) {
      if (!service) return;
      return this.SERVICE_TYPES_INFO[service].pretty_name;
    },
    set_status(status) {
      return this.ORDER_STATUSES[status];
    },
    fulfilment_confirmations(order) {
      return order.confirmations?.filter(
        (confirmation) => confirmation.type === "fulfilment_date"
      );
    },
    // async quick_confirm_fulfilment_date(order_id, fulfilment_date) {
    //   this.confirm_fulfilment_date({
    //     order_id,
    //     shop_name: this.shop.name,
    //     fulfilment_date,
    //   });
    // },
    async confirm_fulfilment_date({
      brand_id,
      order_id,
      shop_name,
      fulfilment_date,
      email_message = null,
    }) {
      this.updating_fulfilment_date = true;

      const confirmation = {
        brand_id,
        shop_name,
        email_message,
        old_val: this.order?.fulfilment_date
          ? this.format_fulfilment(this.order.fulfilment_date)
          : null,
        new_val: this.format_fulfilment(fulfilment_date),
        type: "fulfilment_date",
      };

      const updates = {
        fulfilment_date,
        confirmation,
      };

      try {
        await update_orders(this.shop_id, [order_id], updates);
        this.show_confirm_fulfilment = false;
        this.snackbar = true;
      } catch (error) {
        console.error("Orders/update_orders ", error);
      } finally {
        this.updating_fulfilment_date = false;
        this.selected_order = null;
        this.fulfilment_order = null;
      }
    },
    format_fulfilment(fulfilment) {
      if (!fulfilment) return "Not confirmed";
      if (fulfilment.includes(":")) {
        return moment(fulfilment).format("DD/MM/YYYY HH:mm");
      }
      return moment(fulfilment).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss">
.orders {
  table {
    tr {
      cursor: pointer;
    }
  }
}
.fulfilment-date-chip {
  width: 150px;
  justify-content: center;
  opacity: 1;
  justify-content: space-between;

  span {
    font-weight: 400;
    white-space: pre-wrap;
    line-height: 1;
    text-align: left;
  }
  .chip-content {
    font-size: 1.15em;
  }
}
</style>
