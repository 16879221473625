<template>
  <v-form class="w-100 py-7 px-8" v-model="form_valid">
    <p class="mb-6">Set up tables in their designated areas here.</p>
    <v-alert type="info" class="mb-6" outlined color="primary">
      <span class="dark-grey--text"
        ><b class="black--text">Important</b><br />
        Booking system allocates tables from
        <b class="black--text">Highest to Lowest</b> priority. If the priority
        is set to <b class="black--text">Offline</b> the table can only be used
        for manual bookings and will not be used for online bookings.
      </span>
    </v-alert>
    <div class="new-zone w-100 mb-8 align-center">
      <v-text-field
        autocomplete="off"
        outlined
        dense
        flat
        class="required"
        clearable
        hide-details
        v-model="new_zone_name"
        label="New Area Name"
      />
      <v-btn
        @click="add_new_zone"
        color="primary"
        rounded
        :disabled="!new_zone_name"
        >Add new area</v-btn
      >
    </div>
    <v-divider color="lightgrey" class="mb-7" height="1px" />
    <template v-if="zones.length">
      <div v-for="(zone, i) in zones" :key="`zone_${zone.id}`">
        <div class="--row">
          <v-text-field
            outlined
            dense
            :class="{ 'font-weight-bold': zone.name }"
            placeholder="Type area name"
            append-icon="mdi-pencil-outline"
            color="primary"
            :value="zone.name"
            @blur="(e) => update_zone_name(e, zone.id, zone.name)"
          />
          <v-select
            multiple
            outlined
            dense
            label="Exclude area on days"
            :items="days"
            :value="zone.excluded_day_numbers"
            @change="(e) => update_excluded_days(e, zone.id)"
          />
        </div>

        <!-- // Added by vikas on 11th dec table sorting order  -->

        <div
          class="--row mb-7"
          v-for="(table, j) in tables_by_zone[zone.id]"
          :key="`table_${i}_${j}`"
        >
          <v-text-field
            autocomplete="off"
            outlined
            dense
            flat
            class="--name pb-4 pb-md-0 required"
            clearable
            hide-details
            :rules="[field_req]"
            v-model="table.name"
            label="Table Number/Name"
            @input="$emit('change', true)"
          />
          <v-text-field
            autocomplete="off"
            type="number"
            outlined
            dense
            flat
            class="--capacity pb-4 pb-md-0 required"
            clearable
            hide-details
            :rules="[field_req]"
            v-model.number="table.capacity"
            label="No. of Seats"
            @input="$emit('change', true)"
          />
          <v-select
            autocomplete="off"
            outlined
            dense
            flat
            class="--priority pb-4 pb-md-0 required"
            clearable
            hide-details
            :items="TABLE_PRIORITY_OPTIONS"
            :rules="[field_req]"
            v-model="table.priority"
            label="Priority"
            @input="$emit('change', true)"
          />
          <v-text-field
            autocomplete="off"
            type="number"
            step="1"
            outlined
            dense
            flat
            class="sorting-number-field"
            clearable
            hide-details
            :rules="[
              (value) => !value || field_req(value),
              (value) => !value || validatePositiveNonZeroNumber(value),
              (value) => !value || validateUniqueSorting(value),
            ]"
            v-model.number="table.sorting_order"
            label="Sorting Number"
            @input="
              $emit('change', true);
              form_valid = table.sorting_order > 0;
            "
            @wheel.prevent
          />
          <div
            class="--actions d-flex align-center justify-end justify-md-center"
          >
            <v-btn icon color="accent" @click="delete_table(table)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- // Added by vikas on 11th dec table sorting order  -->
        <div class="--row mb-7">
          <div class="--add-new d-flex justify-end justify-md-center">
            <v-btn
              elevation="1"
              :fab="$vuetify.breakpoint.mdAndUp"
              :x-small="$vuetify.breakpoint.mdAndUp"
              class="mr-0"
              color="primary"
              rounded
              @click="
                add_empty_table_to_zone({ zone_id: zone.id, ...new_table })
              "
              ><v-icon v-if="$vuetify.breakpoint.mdAndUp">mdi-plus</v-icon
              ><span v-else>Add new table</span></v-btn
            >
          </div>
        </div>
      </div>
    </template>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { field_req } from "@/utils/form_val_rules";

const TABLE_PRIORITY = {
  OFFLINE: "1",
  LOW: "2",
  MEDIUM: "3",
  HIGH: "4",
};

const TABLE_PRIORITY_OPTIONS = [
  { value: TABLE_PRIORITY.OFFLINE, text: "None (For Walk-ins Only)" },
  { value: TABLE_PRIORITY.LOW, text: "Low" },
  { value: TABLE_PRIORITY.MEDIUM, text: "Medium" },
  { value: TABLE_PRIORITY.HIGH, text: "High (Use First)" },
];

const new_table = {
  name: null,
  priority: TABLE_PRIORITY.MEDIUM,
  capacity: 2,
  sorting: null, // Added by vikas on 11th dec table sorting order
};

export default {
  name: "BookingSettingsTables",
  props: {
    executeUpdate: { type: Boolean, default: false },
  },
  data() {
    return {
      new_table,
      TABLE_PRIORITY_OPTIONS,
      field_req,
      form_valid: false,
      new_zone_name: null,
      days: [
        { value: 1, text: "Monday" },
        { value: 2, text: "Tuesday" },
        { value: 3, text: "Wednesday" },
        { value: 4, text: "Thursday" },
        { value: 5, text: "Friday" },
        { value: 6, text: "Saturday" },
        { value: 7, text: "Sunday" },
      ],
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("TableBookingStore", ["zones"]),
    ...mapGetters("TableBookingStore", ["tables_by_zone"]),
  },
  async mounted() {
    this.$emit("loading", true);
    try {
      await this.get_zones(this.shop_id);
      await this.get_tables(this.shop_id);
    } catch (error) {
      console.error(error);
    } finally {
      this.$emit("loading", false);
    }
  },
  watch: {
    form_valid(is_valid) {
      this.$emit("form-valid", is_valid);
    },
    async executeUpdate(execute) {
      if (!execute) return;
      await this.do_update();
    },
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "get_zones",
      "get_tables",
      "upsert_tables",
      "delete_table",
      "update_zone",
      "add_zone",
      "add_empty_table_to_zone",
    ]),
    // Added by vikas on 11th dec table sorting order
    validatePositiveNonZeroNumber(value) {
      if (value === null || value === undefined || value === "") {
        return true;
      }
      if (value <= 0) {
        return "Sorting Number must be greater than 0.";
      }
      return true;
    },
    validateUniqueSorting(value) {
      const allSortings = Object.values(this.tables_by_zone)
        .flat()
        .map((table) => table.sorting_order);
      if (value && allSortings.filter((s) => s === value).length > 1) {
        return "This sorting number is already used.";
      }
      return true;
    },
    // Added by vikas on 11th dec table sorting order
    async do_update() {
      try {
        let tables_in = [];
        for (let [zone_id, tables] of Object.entries(this.tables_by_zone)) {
          for (let table of tables) {
            tables_in.push({
              ...table,
              shop_id: this.shop_id,
              zone_id: zone_id,
            });
          }
        }
        await this.upsert_tables(tables_in);

        this.$emit("change", false);
        this.$emit("show-snackbar");
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("update:finished");
      }
    },
    async add_new_zone() {
      try {
        await this.add_zone({
          shop_id: this.shop_id,
          name: this.new_zone_name,
        });
        this.new_zone_name = undefined;
      } catch (error) {
        console.error(error);
      }
    },
    async update_zone_name(e, zone_id, previous_name) {
      const new_name = e.target.value;
      if (!new_name) return;
      if (new_name && new_name === previous_name) return;
      try {
        await this.update_zone({
          shop_id: this.shop_id,
          id: zone_id,
          name: e.target.value,
        });
        this.$emit("show-snackbar");
      } catch (error) {
        console.error(error);
      }
    },
    async update_excluded_days(e, zone_id) {
      try {
        await this.update_zone({
          shop_id: this.shop_id,
          id: zone_id,
          excluded_day_numbers: e,
        });
        this.$emit("show-snackbar");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<!-- // Added by vikas on 11th dec table sorting order -->
<style lang="scss" scoped>
.new-zone {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
  grid-column-gap: 2rem;
}
.--row {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 1fr;
    grid-column-gap: 2rem;
    grid-template-areas: "table-name capacity priority actions";
  }

  @media #{map-get($display-breakpoints, 'xl-only')} {
    grid-template-columns: 3fr 3fr 3fr 1fr 4fr;
  }
  .--name {
    grid-area: table-name;
  }
  .--capacity {
    grid-area: capacity;
  }
  .--priority {
    grid-area: priority;
  }
  .--actions {
    grid-area: actions;
  }
  .--add-new {
    grid-area: 1 / 4 / 1 / 5;
  }
  .sorting-number-field {
    margin-top: 15px; /* Adjust the value as needed */
  }
}
</style>
<!-- // Added by vikas on 11th dec table sorting order -->
