<template>
  <v-dialog
    v-model="show"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="$vuetify.breakpoint.smAndDown ? '' : '620px'"
    @keydown.esc="show = false"
  >
    <v-card class="action-modal" v-if="successfully_created == null">
      <v-card-title
        class="px-4 py-3 primary font-weight-bold secondary--text text-center"
      >
        <span>{{ dialog_title }}</span>
        <v-progress-linear
          v-if="is_fetching_options || is_sending"
          indeterminate
          color="primary lighten-1"
          style="top: 3.7rem"
          class="mb-0"
          absolute
          top
        />
      </v-card-title>
      <v-card-text style="position: relative">
        <v-container class="action-modal-content px-5 pb-5">
          <v-alert
            v-if="is_editing"
            type="info"
            color="primary"
            border="left"
            colored-border
          >
            <span class="dark-grey--text font-weight-medium text-center">
              The system will try to apply the booking's current start time and
              guest count to a newly selected date. You can undo all adjustments
              by clicking 'Revert Changes' at the bottom.
            </span>
          </v-alert>
          <div
            class="d-flex align-center justify-center"
            v-if="!allowed_dates.length && !is_fetching_options"
          >
            <span class="dark-grey--text font-weight-medium text-center">
              Unfortunately there are no dates available for booking at the
              moment. Please check in later.
            </span>
          </div>
          <v-form v-model="form_valid" v-else>
            <v-alert
              v-if="walkIn && !is_editing"
              class="mb-6"
              type="info"
              color="primary"
              border="left"
              dense
              colored-border
            >
              <span class="dark-grey--text font-weight-medium text-center">
                You do not need to fill in any contact details with walk-in
                customers. By doing so will automatically enable them to login
                and manage their bookings.
              </span>
            </v-alert>
            <div
              :class="{
                'flex-column align-center': $vuetify.breakpoint.smAndDown,
              }"
              class="d-flex flex-wrap justify-space-between pb-5"
            >
              <div class="d-flex py-2">
                <v-tooltip top content-class="custom-tooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      v-text="`$account`"
                      class="primary rounded-circle mt-2"
                      color="white"
                      v-on="on"
                    />
                  </template>
                  <span>Guest Name</span>
                </v-tooltip>
                <span class="red--text" v-if="!walkIn && !is_editing">*</span>
                <v-text-field
                  type="text"
                  class="guest-detail-text"
                  :class="{ 'pl-1': walkIn || is_editing }"
                  v-model="user_details.name"
                  hide-details
                  dense
                  single-line
                  maxlength="40"
                  :rules="walkIn ? [] : [field_req]"
                  :required="is_editing ? false : !walkIn"
                  :disabled="is_sending || is_editing"
                />
              </div>
              <div class="d-flex py-2">
                <v-tooltip top content-class="custom-tooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      v-text="`$mail`"
                      class="primary rounded-circle mt-2"
                      color="white"
                      v-on="on"
                    />
                  </template>
                  <span>Guest Email</span>
                </v-tooltip>
                <span class="accent--text" v-if="!walkIn && !is_editing"
                  >*</span
                >
                <v-text-field
                  type="text"
                  class="guest-detail-text"
                  :class="{ 'pl-1': walkIn || is_editing }"
                  v-model="user_details.email"
                  hide-details
                  dense
                  single-line
                  maxlength="40"
                  :rules="walkIn ? [valid_email] : [field_req, valid_email]"
                  :required="is_editing ? false : !walkIn"
                  :disabled="is_sending || is_editing"
                />
              </div>
              <div class="d-flex py-2">
                <v-tooltip top content-class="custom-tooltip">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      v-text="`$phone`"
                      class="primary rounded-circle mt-2"
                      color="white"
                      v-on="on"
                    />
                  </template>
                  <span>Guest Phone No</span>
                </v-tooltip>
                <span class="red--text" v-if="!walkIn && !is_editing">*</span>
                <v-text-field
                  type="number"
                  class="guest-detail-text remove-spinner"
                  :class="{ 'pl-1': walkIn || is_editing }"
                  v-model="user_details.phone_no"
                  hide-details
                  dense
                  single-line
                  maxlength="40"
                  :rules="is_editing ? [] : phone_no_rules"
                  :required="is_editing ? false : !walkIn"
                  :disabled="is_sending || is_editing"
                />
              </div>
              <v-checkbox
                v-if="is_feature_complete"
                class="mt-1"
                v-model="accept_marketing"
                label="Accept Email Marketing?"
                dense
                hide-details
              />
            </div>
            <div class="new-booking-data">
              <v-date-picker
                class="new-booking-calendar br-16"
                v-model="_selected_date"
                show-current="false"
                first-day-of-week="1"
                color="primary"
                :readonly="walkIn"
                :header-date-format="
                  (v) => {
                    return moment(v, `YYYY-MM`).format(`MMMM YYYY`);
                  }
                "
                :allowed-dates="
                  (d) => {
                    return walkIn
                      ? d == moment().format('YYYY-MM-DD')
                      : _allowed_dates.includes(d);
                  }
                "
                no-title
                :disabled="is_sending"
              />
              <div class="new-booking-details pl-8">
                <div class="pt-3 pb-2 d-flex align-start">
                  <span>Status</span>
                  <span>:</span>
                  <v-select
                    v-model="booking_status"
                    :items="BOOKING_STATUS_ITEMS"
                    class="inline-select ml-2 my-0"
                    :readonly="
                      !is_editing || selected_booking.status == 'cancelled'
                    "
                    :disabled="is_sending"
                    dense
                    hide-details
                  />
                </div>
                <div class="pt-3 pb-2 d-flex align-start">
                  <span>Date</span>
                  <span class="accent--text">*</span>
                  <span>:</span>
                  <v-text-field
                    v-model="selected_date_text"
                    class="date-field inline-input ml-2 my-0"
                    readonly
                    :disabled="is_sending"
                    dense
                    hide-details
                  />
                </div>
                <div class="pt-3 pb-2 d-flex align-start">
                  <div class="d-flex align-start mr-4">
                    <span>Ppl</span>
                    <span class="accent--text">*</span>
                    <span>:</span>
                    <v-select
                      v-model="selected_guest_count"
                      class="
                        inline-select
                        guest-count-select
                        inline-input
                        ml-2
                        my-0
                      "
                      :items="walkIn ? walk_in_guest_options : _guest_options"
                      :rules="[field_req]"
                      :disabled="
                        (walkIn
                          ? !walk_in_guest_options.length
                          : !guest_options.length) || is_sending
                      "
                      dense
                      hide-details
                    />
                  </div>
                  <div class="d-flex align-start">
                    <span>+extra</span>
                    <span>:</span>
                    <v-select
                      v-model="extra_guest_count"
                      class="
                        inline-select
                        guest-count-select
                        inline-input
                        ml-2
                        my-0
                      "
                      :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      dense
                      hide-details
                    />
                  </div>
                </div>
                <div class="pt-3 pb-2 d-flex align-start">
                  <div class="d-flex align-start">
                    <span>Time</span>
                    <span class="accent--text">*</span>
                    <span>:</span>
                    <v-select
                      v-model="selected_start_time"
                      class="inline-select time-select inline-input ml-2 my-0"
                      :items="_available_start_times"
                      :rules="[field_req]"
                      :disabled="
                        !_available_start_times.length ||
                        selected_guest_count == null ||
                        is_sending
                      "
                      dense
                      hide-details
                    />
                  </div>
                </div>
                <div class="pt-3 pb-2 d-flex align-start">
                  <div class="d-flex align-start">
                    <span>Allocated Table</span>
                    <span>:</span>
                    <v-select
                      v-model="selected_table"
                      class="inline-select inline-input ml-2 my-0"
                      :items="_available_tables"
                      :item-text="
                        (table) =>
                          `${table.name} (${
                            zones.find((z) => z.id === table.zone_id).name
                          })`
                      "
                      item-value="id"
                      :disabled="
                        !selected_start_time ||
                        selected_guest_count == null ||
                        is_sending
                      "
                      dense
                      hide-details
                    />
                  </div>
                </div>
                <div
                  class="pt-3 pb-2 d-flex align-start"
                  v-if="_available_brands.length > 1"
                >
                  <div class="d-flex align-start">
                    <span>Brand</span>
                    <span class="accent--text">*</span>
                    <span>:</span>
                    <v-select
                      v-model="_selected_brand"
                      class="inline-select inline-input ml-2 my-0"
                      :items="_available_brands"
                      :rules="[field_req]"
                      :disabled="is_sending"
                      dense
                      hide-details
                    />
                  </div>
                </div>
                <div
                  class="pt-3 pb-2 d-flex align-start"
                  v-if="is_feature_complete"
                >
                  <span>Location</span>
                  <span>:</span>
                  <v-select
                    class="inline-select inline-input ml-2 my-0"
                    :items="[`Restaurant`, `Garden`]"
                    dense
                    hide-details
                  />
                </div>
                <div
                  class="pt-3 pb-2 d-flex align-start"
                  v-if="is_feature_complete"
                >
                  <span class="dark-grey--text">Menu Options:</span>
                  <v-chip
                    class="add-options-button ml-2"
                    color="primary"
                    outlined
                  >
                    <span class="text-center">+ Add Options</span>
                  </v-chip>
                </div>
                <v-textarea
                  v-model="new_booking_notes"
                  class="notes-field mr-11"
                  label="Notes:"
                  no-resize
                  background-color="#f0f0f0"
                />
              </div>
              <div class="new-booking-payment" v-if="is_feature_complete">
                <div class="pt-2 d-flex align-start">
                  <span>Full Payment: (£)</span>
                  <v-text-field
                    class="
                      full-payment-field
                      inline-input
                      remove-spinner
                      ml-2
                      my-0
                    "
                    dense
                    hide-details
                    type="number"
                  />
                </div>
                <div>
                  <span class="or">or</span>
                </div>
                <div class="pb-1 d-flex align-start">
                  <span>Booking Deposit: (£)</span>
                  <v-text-field
                    class="deposit-field inline-input remove-spinner ml-2 my-0"
                    dense
                    hide-details
                    type="number"
                  />
                </div>
              </div>
            </div>
          </v-form>
        </v-container>
        <div
          class="action-bar d-flex justify-end mt-4 mb-1 mr-4"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'justify-space-around'
              : 'justify-end'
          "
        >
          <v-btn
            class="mr-3"
            :color="
              !allowed_dates.length && !is_fetching_options
                ? `primary`
                : `dark-grey`
            "
            text
            rounded
            @click="show = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="is_editing"
            :disabled="!is_modified"
            class="mr-3"
            color="accent"
            text
            rounded
            @click="populate_with_existing_booking_variables"
          >
            Revert Changes
          </v-btn>
          <v-btn
            color="primary"
            class="add-button"
            text
            rounded
            v-if="!(!allowed_dates.length && !is_fetching_options)"
            :disabled="
              !form_valid || is_sending || (is_editing && !is_modified)
            "
            :loading="is_sending"
            @click="submit_booking"
          >
            {{ is_editing ? "Update Booking" : "Add Booking" }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-if="
        successfully_created || (successfully_created == false && !is_editing)
      "
    >
      <v-card-title
        class="px-4 py-3 font-weight-bold secondary--text text-center"
        :class="successfully_created ? 'primary' : 'accent'"
      >
        <span>{{
          successfully_created
            ? `Booking ${is_editing ? "Updated" : "Created"}`
            : "Failed to Create Booking"
        }}</span>
      </v-card-title>
      <div class="d-flex flex-column align-center" v-if="successfully_created">
        <span
          class="text-h4 py-10 dark-grey--text font-weight-medium text-center"
        >
          Successfully {{ is_editing ? "updated" : "created" }} the following
          booking
        </span>
        <div class="successful-booking-section pa-6 br-8">
          <div
            class="d-flex align-center"
            v-for="(field, i) in successful_booking_text"
            :key="`created_field_${i}`"
          >
            <span class="font-weight-medium dark-grey--text">{{
              `${field.title}: `
            }}</span>
            <span class="ml-1 dark-grey--text">{{ field.text }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex align-center justify-center" v-else>
        <span
          class="
            text-h4
            py-10
            px-6
            dark-grey--text
            font-weight-medium
            text-center
          "
          >Something went wrong while creating the booking. Please try again or
          contact your shop admin if the problem persists
        </span>
      </div>
      <div class="action-bar d-flex justify-end my-5 mr-4">
        <v-btn
          color="primary"
          text
          rounded
          @click="
            successfully_created
              ? (show = false)
              : (successfully_created = null)
          "
        >
          Go Back
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

import {
  field_req,
  valid_email,
  is_number,
  length_max,
} from "@/utils/form_val_rules";
import { BOOKING_STATUS_ITEMS } from "@/shared/const.js";

export default {
  name: "NewBookingDialog",
  props: {
    walkIn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
      BOOKING_STATUS_ITEMS,
      field_req,
      valid_email,
      show_edit_snackbar: true,
      booking_status: "new",
      form_valid: false,
      accept_marketing: false,
      selected_date: null,
      selected_guest_count: null,
      selected_start_time: null,
      selected_brand: null,
      new_booking_notes: null,
      extra_guest_count: null,
      selected_table: null,
      created_booking: null,
      successfully_created: null,
      watch_guest_count: false,
      user_details: {
        name: "",
        email: "",
        phone_no: "",
      },
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("UserStore", ["user"]),
    ...mapState("ShopStore", ["shop"]),
    ...mapState("StaffStore", ["logged_in_staff"]),
    ...mapState("TableBookingStore", [
      "show_new_booking",
      "allowed_dates",
      "allowed_walk_in_dates",
      "guest_options",
      "walk_in_guest_options",
      "available_start_times",
      "available_walk_in_start_times",
      "is_sending",
      "is_fetching_options",
      "selected_booking",
      "selected_booking_date",
      "tables",
      "zones",
    ]),
    show: {
      get() {
        return this.show_new_booking;
      },
      set(value) {
        if (!value) {
          this.toggle_show_new_booking();
          this.$emit("close");
        }
        this.$emit("refresh-data"); // Changes by Vikas Table Booking
      },
    },
    _selected_date: {
      get() {
        if (this.selected_date) return this.selected_date;
        return this._allowed_dates.includes(this.selected_booking_date)
          ? this.selected_booking_date
          : this._allowed_dates[0];
      },
      set(date) {
        this.selected_date = date;
      },
    },
    _selected_brand: {
      get() {
        return this.selected_brand || this.shop.brands[0].id;
      },
      set(brand) {
        this.selected_brand = brand;
      },
    },
    is_editing() {
      return this.selected_booking != null;
    },
    is_modified() {
      if (!this.is_editing) return false;
      const start_at_moment = moment.utc(this.selected_booking.start_at);
      return (
        this._selected_date != start_at_moment.format("YYYY-MM-DD") ||
        this.selected_guest_count != this.selected_booking.guest_count ||
        this.selected_start_time != start_at_moment.format("hh:mm a") ||
        this.booking_status != this.selected_booking.status ||
        this.new_booking_notes != this.selected_booking.notes ||
        this.selected_table != this.selected_booking.table_id ||
        this.extra_guest_count != this.selected_booking.extra_guest_count
      );
    },
    _allowed_dates() {
      if (!this.is_editing && !this.walkIn) return this.allowed_dates;
      else if (!this.is_editing && this.walkIn)
        return this.allowed_walk_in_dates;
      const existing_booking_date = moment
        .utc(this.selected_booking.start_at)
        .format("YYYY-MM-DD");
      return this.allowed_dates.includes(existing_booking_date)
        ? this.allowed_dates
        : [...this.allowed_dates, existing_booking_date];
    },
    _guest_options() {
      return this.is_editing &&
        !this._allowed_dates.includes(this._selected_date)
        ? [this.selected_booking.guest_count]
        : this.guest_options;
    },
    _available_start_times() {
      if (!this.selected_guest_count) return [];
      if (
        this.is_editing &&
        !this._allowed_dates.includes(this._selected_date)
      ) {
        return [moment.utc(this.selected_booking.start_at).format("hh:mm a")];
      }
      let target_times = this.walkIn
        ? this.available_walk_in_start_times
        : this.available_start_times;
      if (
        this.is_editing &&
        this._selected_date ==
          moment.utc(this.selected_booking.start_at).format("YYYY-MM-DD") &&
        this.selected_guest_count == this.selected_booking.guest_count &&
        !Object.keys(target_times).includes(
          moment.utc(this.selected_booking.start_at).format("HH:mm:ss")
        )
      ) {
        target_times[
          moment.utc(this.selected_booking.start_at).format("HH:mm:ss")
        ] = [];
      }
      const start_time_moments = Object.keys(target_times).map((time) =>
        moment([this._selected_date, time].join(" "), "YYYY-MM-DD HH:mm:ss")
      );
      const sorted_times = start_time_moments.sort(
        (a, b) => a.unix() - b.unix()
      );
      return sorted_times.map((time) => time.format("hh:mm a"));
    },
    _available_tables() {
      let target_times = this.walkIn
        ? this.available_walk_in_start_times
        : this.available_start_times;

      const selected_time = this.selected_start_time_ISO;

      if (!selected_time) return [];
      if (!target_times[selected_time]) return [];

      const available_tables = this.tables.filter((t) =>
        target_times[selected_time].includes(t.id)
      );
      if (!this.is_editing) {
        return available_tables;
      }

      const append_already_assigned_table =
        this._selected_date ==
          moment.utc(this.selected_booking.start_at).format("YYYY-MM-DD") &&
        this.selected_guest_count == this.selected_booking.guest_count &&
        moment.utc(this.selected_booking.start_at).format("HH:mm:ss") ===
          selected_time;

      if (append_already_assigned_table)
        return [this.selected_booking.table, ...available_tables];
      return available_tables;
    },
    _available_brands() {
      return this.shop.brands.map((brand) => {
        return { text: brand.name, value: brand.id };
      });
    },
    selected_date_text() {
      return (
        moment(this._selected_date, "YYYY-MM-DD").format("Do MMMM YYYY") || ""
      );
    },
    successful_booking_text() {
      return [
        { title: "Guest Name", text: this.created_booking.user.name },
        { title: "No of Ppl", text: this.created_booking.guest_count },
        {
          title: "Starts",
          text: moment(this.created_booking.start_at)
            .utc()
            .format("Do MMMM, hh:mm a"),
        },
        { title: "Assigned Table", text: this.created_booking.table.name },
      ];
    },
    selected_start_time_ISO() {
      return (
        moment(this.selected_start_time, "hh:mm a").format("HH:mm") + ":00"
      );
    },
    is_feature_complete() {
      return process.env.VUE_APP_TABLE_BOOKING_COMPLETE == "True";
    },
    dialog_title() {
      if (this.is_editing) return "Edit Booking";
      if (!this.walkIn && !this._allowed_dates.length) return "Fully Booked";
      return this.walkIn ? "New Walk-in" : "New Booking";
    },
    phone_no_rules() {
      return this.walkIn
        ? [is_number, (v) => length_max(v, 11)]
        : [field_req, is_number, (v) => length_max(v, 11)];
    },
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "toggle_show_new_booking",
      "get_guests_options",
      "get_walk_in_guests_options",
      "get_available_table_start_times",
      "get_available_walk_in_start_times",
      "create_booking",
      "display_update_snackbar",
      "update_booking",
    ]),
    populate_with_existing_booking_variables() {
      const selected_start_at_moment = moment.utc(
        this.selected_booking.start_at
      );
      this.selected_date = selected_start_at_moment.format("YYYY-MM-DD");
      this.selected_start_time = selected_start_at_moment.format("hh:mm a");
      this.user_details = { ...this.selected_booking.user };
      this.booking_status = this.selected_booking.status;
      this.selected_guest_count = this.selected_booking.guest_count;
      this.new_booking_notes = this.selected_booking.notes;
      this.extra_guest_count = this.selected_booking.extra_guest_count;
      this.selected_table = this.selected_booking.table_id;
    },
    apply_existing_guest_count() {
      if (!this._guest_options.includes(this.selected_guest_count)) {
        if (!this.is_editing) {
          this.selected_guest_count = null;
          return;
        }
        if (this._guest_options.includes(this.selected_booking.guest_count)) {
          this.selected_guest_count = this.selected_booking.guest_count;
        } else {
          const guest_count_str =
            this.selected_guest_count == this.selected_booking.guest_count
              ? `${this.selected_guest_count}`
              : [
                  this.selected_guest_count,
                  this.selected_booking.guest_count,
                ].join(" or ");
          this.display_update_snackbar(
            `Cannot accomodate ${guest_count_str} guests at the selected date`
          );
          this.selected_guest_count = null;
        }
      }
    },
    apply_existing_start_time() {
      if (!this._available_start_times.includes(this.selected_start_time)) {
        if (!this.is_editing) {
          this.selected_start_time = null;
          return;
        }
        const existing_start_time_str = moment
          .utc(this.selected_booking.start_at)
          .format("hh:mm a");
        if (this._available_start_times.includes(existing_start_time_str)) {
          this.selected_start_time = existing_start_time_str;
        } else {
          const start_at_str =
            this.selected_start_time == existing_start_time_str ||
            !this.selected_start_time
              ? `${existing_start_time_str} is`
              : [existing_start_time_str, this.selected_start_time].join(
                  " or "
                ) + " are";
          this.display_update_snackbar(
            `${start_at_str} not available for the selected date and guest count`
          );
          this.selected_start_time = null;
        }
        this.apply_existing_table();
      }
    },
    apply_existing_table() {
      const available_tables = this._available_tables.map((t) => t.id);
      if (!available_tables.includes(this.selected_table)) {
        if (!this.is_editing) {
          this.selected_table = null;
          return;
        }
        const existing_table = this.selected_booking.table_id;
        if (available_tables.includes(existing_table)) {
          this.selected_table = existing_table;
        } else {
          this.display_update_snackbar(
            `Table ${
              this.tables.find((t) => t.id === existing_table).name
            } not available for the selected date and guest count`
          );
          this.selected_table = null;
        }
      }
    },
    async get_guest_options_for_selected_date() {
      await this.get_guests_options({
        shop_id: this.shop_id,
        params: {
          requested_date: this._selected_date,
        },
      });
      if (this.guest_options.includes(this.selected_guest_count)) {
        await this.get_available_booking_start_times();
      }
    },
    async get_available_booking_start_times() {
      await this.get_available_table_start_times({
        shop_id: this.shop_id,
        params: {
          requested_date: this._selected_date,
          guests: this.selected_guest_count,
          ignore_booking_id: this.selected_booking?.id ?? null,
        },
      });
    },
    async get_initial_walk_in_data() {
      await this.get_walk_in_guests_options(this.shop_id);
      if (this.walk_in_guest_options.length) {
        this.selected_guest_count =
          this.walk_in_guest_options.find((i) => i > 1) ||
          this.walk_in_guest_options[0];
        await this.get_available_walk_in_start_times({
          shop_id: this.shop_id,
          params: {
            guests: this.selected_guest_count,
            ignore_booking_id: this.selected_booking?.id ?? null,
          },
        });
        if (this._available_start_times.length)
          this.selected_start_time = this._available_start_times[0];
      }
    },
    submit_booking() {
      if (this.is_editing) {
        this.submit_booking_update();
      } else {
        this.submit_new_booking();
      }
    },
    async submit_booking_update() {
      const start_times = this.walkIn
        ? this.available_walk_in_start_times
        : this.available_start_times;

      const table_id =
        this.selected_table &&
        this._available_tables.map((t) => t.id).includes(this.selected_table)
          ? this.selected_table
          : start_times[this.selected_start_time_ISO][0];
      const params = {
        id: this.selected_booking.id,
        status: this.booking_status,
        start_at: [this._selected_date, this.selected_start_time_ISO].join("T"),
        guest_count: this.selected_guest_count,
        extra_guest_count: this.extra_guest_count,
        notes: this.new_booking_notes,
        table_id,
      };
      this.created_booking = await this.update_booking(params);
      this.successfully_created = this.created_booking != null;
    },
    async submit_new_booking() {
      const start_times = this.walkIn
        ? this.available_walk_in_start_times
        : this.available_start_times;
      let params = {
        booking_in: {
          shop_id: this.shop_id,
          brand_id: this._selected_brand,
          guest_count: this.selected_guest_count,
          extra_guest_count: this.extra_guest_count,
          start_at: [this._selected_date, this.selected_start_time_ISO].join(
            "T"
          ),
          type: "table",
          origin: "admin",
          table_id: this.selected_table
            ? this.selected_table
            : start_times[this.selected_start_time_ISO][0],
          staff_id: this.logged_in_staff?.id || null,
          notes: this.new_booking_notes,
        },
      };
      if (!Object.values(this.user_details).includes("")) {
        params = {
          ...params,
          user_name: this.user_details.name,
          email: this.user_details.email,
          phone_no: this.user_details.phone_no,
        };
      }
      this.created_booking = await this.create_booking(params);
      this.successfully_created = this.created_booking != null;
    },
  },
  watch: {
    async _selected_date(date) {
      if (date && this._allowed_dates.includes(date)) {
        await this.get_guest_options_for_selected_date();
      }
      this.apply_existing_guest_count();
      if (this.selected_guest_count) {
        this.apply_existing_start_time();
      }
    },
    async selected_guest_count(count) {
      if (this.watch_guest_count && count) {
        if (this.walkIn) {
          await this.get_available_walk_in_start_times({
            shop_id: this.shop_id,
            params: {
              guests: count,
              ignore_booking_id: this.selected_booking?.id ?? null,
            },
          });
          if (!this._available_start_times.includes(this.selected_start_time)) {
            this.selected_start_time = this._available_start_times.length
              ? this._available_start_times[0]
              : null;
          }
        } else {
          await this.get_available_booking_start_times();
        }
        this.apply_existing_start_time();
      }
    },
  },
  async mounted() {
    if (this.is_editing) {
      this.populate_with_existing_booking_variables();
    }
    if (this.walkIn) {
      await this.get_initial_walk_in_data();
    } else if (this._allowed_dates.includes(this._selected_date)) {
      await this.get_guest_options_for_selected_date();
    }
    this.watch_guest_count = true;
  },
};
</script>
<style lang="scss" scoped>
.guest-detail-text ::v-deep {
  max-width: 130px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: 100%;
  }
}
.v-input--selection-controls {
  margin-top: 0;
}
.new-booking-data ::v-deep {
  display: grid;
  grid-template-columns: 280px 266px;
  grid-template-rows: auto;
  grid-template-areas: "calendar booking payment";
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: 280px;
    grid-template-rows: auto auto auto;
    grid-row-gap: 10px;
    grid-template-areas:
      "calendar"
      "booking"
      "payment";
    margin-left: calc(50% - 140px);
  }
  .new-booking-calendar {
    grid-area: calendar;
    border: 2px solid var(--v-light-grey-base);
    z-index: 10;
    .v-picker__body {
      max-width: 276px;
      .v-date-picker-header {
        max-width: 276px;
        margin: 0 12px;
        padding: 0;
        .v-icon {
          color: var(--v-primary-base);
        }
        button {
          color: var(--v-dark-grey-base) !important;
          &:hover {
            color: var(--v-primary-base) !important;
          }
        }
      }
      .v-date-picker-table--date th {
        padding-top: 2px;
      }
      .v-date-picker-table {
        max-width: 276px;
        padding: 0;
        th {
          color: var(--v-dark-grey-base);
          font-size: 14px;
          font-weight: normal;
        }
        tr {
          td {
            button {
              background-color: white !important;
              font-size: 14px;
              font-weight: 500;
              .v-btn__content {
                color: var(--v-dark-grey-base);
              }
              &.v-btn--disabled {
                .v-btn__content {
                  color: var(--v-completed-base);
                }
              }
              &.v-btn--active {
                border: 2px solid;
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  .inline-input {
    max-height: 22px;
    * {
      max-height: 22px;
    }
  }
  .new-booking-details {
    grid-area: booking;
    .inline-select {
      max-width: 110px;
      .v-input__append-inner {
        max-width: 14px;
      }
      .v-icon {
        margin-bottom: 3px;
        background: transparent;
      }
      .v-select__selection {
        max-width: 100%;
        margin-right: 0;
        text-overflow: ellipsis;
      }
      .v-select__selections {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .date-field {
      max-width: 140px;
      input {
        font-size: 14px;
        font-weight: normal;
        padding-bottom: 0;
      }
    }
    .guest-count-select {
      max-width: 30px;
    }
    .time-select {
      max-width: 80px;
    }
    .add-options-button {
      margin-top: 3px;
      max-height: 24px;
      transition: background-color 250ms linear;
      * {
        max-height: 24px;
      }
      &:hover {
        background-color: var(--v-primary-lighten5) !important;
        transition: background-color 250ms linear;
        cursor: pointer;
      }
    }
    span {
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      color: var(--v-dark-grey-base);
    }
  }
  .new-booking-payment {
    grid-area: payment;
    .full-payment-field {
      max-width: 74px;
    }
    .deposit-field {
      max-width: 52px;
    }
    .or {
      font-size: 11px;
      font-weight: normal;
      font-style: italic;
      max-height: 15px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      color: var(--v-dark-grey-base);
    }
  }
  .new-booking-menu {
    grid-area: menu;
  }
}
.action-bar ::v-deep {
  .add-button {
    background: #e4f6f6;
  }
}
.successful-booking-section {
  display: grid;
  grid-template-columns: 230px;
  grid-template-rows: repeat(4, auto);
  grid-row-gap: 10px;
  border: 1px solid black;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 230px 230px;
    grid-template-rows: repeat(2, auto);
  }
}
.notes-field ::v-deep {
  border-radius: 5px;
  width: 223px;
  * {
    max-height: 82px;
    font-size: 11px;
  }
  .v-input__slot {
    padding: 0 8px;
    margin: 0;
    &::before {
      border: none;
    }
  }
}
</style>
