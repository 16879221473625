<template>
  <v-dialog
    width="370px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    content-class=" ma-0"
    v-model="show"
    @keydown="handle_pin_input($event), (pressed_key = $event.key)"
    @keydown.esc="show = false"
  >
    <v-card class="pin-card white">
      <v-card-title
        class="
          py-4
          px-6
          mb-2
          justify-center
          text-h3
          foodie
          font-weight-bold
          white--text
        "
        >Enter your pin</v-card-title
      >
      <div class="input-container d-flex justify-space-around ma-4">
        <div
          v-for="index in max_length"
          :key="`node_${index}`"
          class="input-node d-flex align-center justify-center"
          :class="{ 'error-node': error_message }"
        >
          <div
            class="dot"
            :class="{
              filled: index <= _value.length,
              'error-dot': error_message,
            }"
          ></div>
        </div>
      </div>
      <div class="login-feedback-wrapper">
        <v-progress-circular
          v-if="loading"
          class="loading-circular"
          color="primary"
          size="24"
          indeterminate
        />
        <span
          v-else
          v-show="error_message"
          class="message text-center font-weight-bold mb-1 noselect"
          :class="{ error: error_message, pin: !error_message }"
        >
          {{ error_message }}
        </span>
      </div>
      <div class="container">
        <div
          v-for="pad in pads"
          :key="`pad_${pad.value}`"
          class="key d-flex flex-column justify-center align-center noselect"
          :class="{ active: pressed_key === pad.value }"
          @click="handle_pad_click(pad)"
        >
          <div v-if="pad.number">
            <span
              class="text-h1 foodie--text text--darken-1 font-weight-bold"
              >{{ pad.value }}</span
            >
          </div>
          <div v-else-if="pad.icon">
            <v-icon color="foodie" large> {{ pad.icon }} </v-icon>
          </div>
        </div>
      </div>
      <div class="text-center font-weight-bold pb-6 pt-4">
        <a @click="show = false">
          <v-icon small color="foodie">mdi-arrow-left-bold</v-icon>
          <span class="mt-1 ml-1">Back to Menu</span>
        </a>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Keypad",
  data() {
    return {
      pressed_key: null,
      pads: [
        { value: "1", number: true },
        { value: "2", number: true },
        { value: "3", number: true },
        { value: "4", number: true },
        { value: "5", number: true },
        { value: "6", number: true },
        { value: "7", number: true },
        { value: "8", number: true },
        { value: "9", number: true },
        {},
        { value: 0, number: true },
        {
          value: "Backspace",
          number: false,
          icon: "mdi-backspace",
          action: this.backspace,
        },
      ],
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    max_length: {
      type: Number,
      default: 4,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    welcome_message: {
      type: String,
      default: "Enter your 4-digit pin to access",
    },
    error_message: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this._value = "";
          this.$emit("close");
        }
      },
    },
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        if (value.length > this.max_length)
          value = value.substring(0, this.max_length);
        this.$emit("input", value);
      },
    },
  },
  methods: {
    backspace() {
      if (!this._value) return;
      this._value = this._value.slice(0, -1);
    },
    // Changes done by vikas to fix 0 not working issue fix on 12the dec
    handle_pad_click(pad) {
      if (pad.value === null || pad.value === undefined) return;
      if (pad.number) {
        this._value = this._value + pad.value;
        return;
      }
      pad.action();
    },
    // Changes done by vikas to fix 0 not working issue fix on 12the dec till here
    handle_pin_input(event) {
      const key = event.key;
      if (key.startsWith("F")) return; // Prevents F1-F12 keys from passing the input check
      if (/[\d]+/.test(key)) {
        this._value = this._value + key;
        return;
      }
      if (key === "Backspace") this.backspace();
    },
  },
  mounted() {
    window.addEventListener("keyup", () => {
      this.pressed_key = null;
    });
  },
};
</script>
<style lang="scss" scoped>
.login-feedback-wrapper {
  height: 30px;
  grid-area: 2 / 1 / 3 /4;
  position: relative;
  .message {
    position: absolute;
    width: 100%;
    bottom: 20%;
    background-color: transparent !important;
    color: transparent;
    transition: color 250ms linear;
    &.error {
      color: var(--v-error-base);
      transition: color 250ms linear;
    }
    &.pin {
      color: #757575;
      transition: color 250ms linear;
    }
  }
  .loading-circular {
    position: absolute;
    width: 30px;
    height: 30px;
    left: calc(50% - 15px);
    top: calc(50% - 32px);
  }
}
.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 15px;
  column-gap: 10px;
  row-gap: 30px;
  @media only screen and (min-width: 321px) {
    padding: 10px 30px;
    column-gap: 30px;
  }
}

.pin-card {
  border-radius: 10px;
}

.key:not(:nth-last-child(3)) {
  width: 68px;
  margin: 0 auto;
  height: 68px;
  box-shadow: 0 1px 8px 0 rgba(84, 196, 201, 0.25);
  border-radius: 99px;
  cursor: pointer;

  &:active,
  &.active {
    background: rgba(84, 196, 201, 0.25);
  }
}

.input-node {
  margin: 4px;
  height: 16px;
  width: 16px;
  border-radius: 99px;
  border: 1px solid var(--v-primary-darken1);
  transition: border 150ms linear;
  background: white;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &.error-node {
    border: 1px solid var(--v-error-base);
    transition: border 150ms linear;
  }
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 99px;
    background: white;
    transition: background 150ms linear;
    &.filled {
      background: var(--v-primary-base);
      transition: background 150ms linear;
      &.error-dot {
        background: var(--v-error-base);
        transition: background 150ms linear;
      }
    }
  }
}
</style>
